import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import { Component4, Design, premium1, rea, wood } from "../assets";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="overflow-x-hidden -mt-12">
      <div className="max-w-screen-lg mx-auto grid md:grid-cols-4 md:gap-10 mt-24 pb-24">
        <div className="group cursor-pointer text-center">
          <img
            className="object-cover mx-auto w-10 h-8 mt-5 mb-5"
            src={Component4}
            alt="img1"
          />
          <h2 className="font-semibold text-sm md:text-lg">CUSTOM DESIGN</h2>
          <p className="text-justify text-[12px] md:text-sm pl-8 md:pl-0 pr-8 md:pr-0">
            Experience bespoke furniture, meticulously crafted to match your
            unique style. Discover wood heritage with carbon dating
            certificates. Contact our expert team for custom creations.
          </p>
        </div>

        <div className="group cursor-pointer text-center">
          <img
            className="object-cover mx-auto w-10 h-8 mt-5 mb-5"
            src={premium1}
            alt="img2"
          />
          <h2 className="font-semibold text-sm md:text-lg">HIGH-END QUALITY</h2>
          <p className="text-justify text-[12px] md:text-sm pl-8 md:pl-0 pr-8 md:pr-0">
            Our unwavering commitment to flawless craftsmanship defines us.
            Quality is our top priority, crafting durable furniture with
            attention to detail. Invest in furniture that stands the test of
            time.
          </p>
        </div>

        <div className="group cursor-pointer text-center">
          <img
            className="object-cover mx-auto w-10 h-8 mt-5 mb-5"
            src={Design}
            alt="img2"
          />
          <h2 className="font-semibold text-sm md:text-lg">MADE IN NIGERIA</h2>
          <p className="text-justify text-[12px] md:text-sm pl-8 md:pl-0 pr-8 md:pr-0">
            Proudly made in Nigeria, with offices and factories across Nigeria,
            our commitment to regional production ensures top-tier quality by
            closely overseeing manufacturing processes.
          </p>
        </div>

        <div className="group cursor-pointer text-center">
          <img
            className="object-cover mx-auto w-8 h-8 mt-5 mb-5"
            src={rea}
            alt="img3"
          />
          <h2 className="font-semibold text-sm md:text-lg">NO INTERMEDIARY</h2>
          <p className="text-justify text-[12px] md:text-sm pl-8 md:pl-0 pr-8 md:pr-0">
            Choosing Wooden Heritage means direct contact with the manufacturer,
            shortening the supply chain for top quality. You support local
            craftsmanship, 'Made in Nigeria,' and sustainability.
          </p>
        </div>
      </div>

      <div className="bg-[#363636] text-white py-12">
        <div className="max-w-screen-lg mx-auto md:flex items-center justify-between pb-2">
          <div className="flex md:flex-col gap-4 md:mt-4  md:translate-y-0 translate-x-4 md:translate-x-0">
            <img src={wood} alt="wooden" style={{ width: "100px" }} />
            <p className="text-white text-xs translate-y-1 md:translate-y-0 tracking-wide">
              CLASSIC FURNITURE <br /> MADE IN NIGERIA
            </p>
           <div className="hidden md:flex gap-5 text-xs cursor-pointer mt-4">
              <a href="https://www.instagram.com/woodenheritage_/">
                <FaInstagram
                  className="h-4"
                  style={{ 
                    width: "20px",
                    height: "20px",
                    border: "1px solid",
                    borderRadius: "15px",
                    padding: "3px",
                  }}
                />
              </a>
              <a href="https://twitter.com/woodenheritage_">
                <FaTwitter
                  className="w-4"
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "1px solid",
                    borderRadius: "15px",
                    padding: "3px",
                  }}
                />
              </a>
              <a href="https://www.facebook.com/woodenheritage1">
                <FaFacebookF
                  className="w-4"
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "1px solid",
                    borderRadius: "15px",
                    padding: "3px",
                  }}
                />
              </a>
              <a href="http://linkedin.com/company/woodenheritage">
                <FaLinkedin
                  className="font"
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "1px solid",
                    borderRadius: "15px",
                    padding: "3px",
                  }}
                />
              </a>
            </div>
          </div>

          <div className=" text-[10px] md:text-xs flex flex-col gap-3 translate-y-1 md:translate-y-0 translate-x-5 md:translate-x-0">
            <div className="flex flex-col gap-4 md:gap-5 -mb-4">
              <p>
                COOPERATE OFFICE: Plot 1003 Morija Close, off <br />
                Adetokunbo  Ademola Cres, North West, Abuja. </p>
             
                <p>
                OPERATION OFFICE: 22 Eng. Ayo Adeniyi street, <br />
                Eyinni,   Orita Challenge,Ibadan, 
                Oyo State, Nigeria.
              </p>
             
              <div className="flex flex-col">
               <p> +234 912 000 0601  | +234 904 664 8867 (call only) </p>
               
               <p> <a href="mailto:info@rkwoodenheritage.com"> info@rkwoodenheritage.com</a></p>
              </div>
            </div>
          </div>

          <div className=" text-[10px] md:text-xs flex md:flex-col gap-8 md:gap-3 mb-2 translate-y-12 md:translate-y-0 translate-x-5 md:translate-x-0">
            <Link to="/aboutUs">
              <h2 className="text-white mb-4">WHO ARE WE?</h2>
            </Link>

            <Link to="/product/All Products">
              <p className="text-white mb-4">OUR PRODUCTS</p>
            </Link>

            <Link to="/blogPage">
              <p>BLOG </p>
            </Link>
          </div>

          <div className="text-[10px] md:text-xs flex md:flex-col gap-8 md:gap-2 mb-4 md:mb-4 translate-y-12 md:translate-y-0 translate-x-5 md:translate-x-0">
            <Link to="/contactUs">
              <h2 className="text-white mb-2">CONTACT US</h2>
            </Link>
            <div className="text-[10px] md:text-xs flex md:flex-col gap-3">
              <Link to="/sales">
                <p>TERMS OF SALES</p>
              </Link>
              <Link to="/faq">
                <p>FAQs </p>
              </Link>
              <Link to="/faq">
                <p>SUPPORT</p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-white h-20 translate-x-5 md:translate-x-0">
        <div className="max-w-screen-lg h-full mx-auto flex items-center justify-between">
          <div className="text-[#352214] text-xs md:flex">
            <p>Copyright</p>
            <p className="md:ml-8">© 2023 Wooden Heritage</p>
          </div>

          <div className="text-[#352214] text-xs md:flex">
            <Link to="/refund">
              <p className="-translate-x-12 md:translate-x-0">Refund Policy</p>
            </Link>
            <Link to="/warranty">
              <p className="-ml-12 md:ml-8">Warranty Policy</p>
            </Link>
            <Link to="/privacy">
              <p className="-ml-12 md:ml-8">Privacy Policy</p>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
