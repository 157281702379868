import React from "react";

function ResetPassword() {
  return (
    <div className="w-full bg-[#edebe8] pb-40">
      <div className="max-w-screen-lg mx-auto py-10 bg-[#edebe8]">
        <h2 className="text-xl font-bold mt-36 text-[#352214]">MY ACCOUNT</h2>
        <div className="w-9 h-1 bg-[#352214]"></div>
      </div>
      <div className="max-w-screen-lg mx-auto flex gap-28 bg-[#edebe8] mt-10">
        {/*  */}
        <div className="w-5/5 ">
          <h2 className="text-xs font-semibold mb-1"> 
          Forgot your password ? Please enter your username or email address. 
          You will receive a link via email to create a new password.</h2>
          <form>
            <div className="mb-10">
              <input
                className="shadow appearance-none border rounded w-full cursor-pointer py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder="Email"
              />
            </div>
        
          
            <button
              className="bg-[#352214] text-white hover:bg-white hover:text-[#352214] 
              font-semibold py-2 px-4 rounded focus:outline-none w-60 cursor-pointer" 
              type="button"
            >
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
