import React from "react";
import Home from "./pages/Home";
import Header from "./headerNfooter/Header";
import Footer from "./headerNfooter/Footer";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import Cart from "./pages/Cart";

import Product from "./components/Product";
import ProductPage from "./pages/ProductPage";
import Faq from "./privacy/Faq";
import PrivacyPolicy from "./privacy/PrivacyPolicy";
import RefundPolicy from "./privacy/RefundPolicy";
import WarrantyPolicy from "./privacy/WarrantyPolicy";
import SalesTerm from "./privacy/SalesTerm";
import UserAccount from "./pages/UserAccount";

import ResetPassword from "./pages/ResetPassword";

import Coffee from "./pages/Coffee";
import Dining from "./pages/Dining";
import Office from "./pages/Office";
import AnteRoom from "./pages/AnteRoom";
import WorkStation from "./pages/WorkStation";
import TableAccessories from "./pages/TableAccessories";
import Contact from "./pages/Contact";
import SimilarProduct from "./product/SimilarProduct";
import About from "./pages/About";
import AllProducts from "./pages/AllProducts";
import ScrollButton from "./scroll/ScrollButton";
import Order from "./order/Order";
import BlogPage from "./blog/BlogPage";
import { productsData } from "./api/Api";
import WorkStationDesk from "./pages/WorkStationDesk";
import BlogDetails from "./blog/BlogPost";
import BlogPost from "./blog/BlogPost";
import HorizontalAccordion from "./components/HorizontalAccordion";



const Layout = () => {
  return (
    <div>
      <Header />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
        loader: productsData,
      },
      {
        path: "/aboutUs",
        element: <About />,
      },
      {
        path: "/products/:id",
        element: <Product />,
        loader: productsData,
      },
      {
        path: "/cart",
        element: <Cart />,
      },
      {
        path: "/hip",
        element: <HorizontalAccordion />,
      },
      {
        path: "/productPage",
        element: <ProductPage />,
      },
      {
        path: "/faq",
        element: <Faq />,
      },
      {
        path: "/privacy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/refund",
        element: <RefundPolicy />,
      },
      {
        path: "/warranty",
        element: <WarrantyPolicy />,
      },
      {
        path: "/sales",
        element: <SalesTerm />,
      },
      {
        path: "/account",
        element: <UserAccount />,
      },
      {
        path: "/resetPassword",
        element: <ResetPassword />,
      },
      {
        path: "/blogPage",
        element: <BlogPage />,
      },
      {
        path: "/blog/:id",
        element: <BlogPost />,
      },
      {
        path: "/similar/:id",
        element: <SimilarProduct />,
        loader: productsData,
      },

      {
        path: "/product/Coffee Table",
        element: <Coffee />,
      },
      {
        path: "/product/Dinning Table",
        element: <Dining />,
      },
      {
        path: "/product/Office Table",
        element: <Office />,
      },
      {
        path: "/product/Ante Room Table",
        element: <AnteRoom />,
      },
      {
        path: "/product/Workstation Table",
        element: <WorkStation />,
      },
      {
        path: "/product/Workstations Desks",
        element: <WorkStationDesk />,
      },
      {
        path: "/product/Table Accessories",
        element: <TableAccessories />,
      },
      {
        path: "/contactUs",
        element: <Contact />,
      },
      {
        path: "/product/All Products",
        element: <AllProducts />,
      },
      {
        path: "/order",
        element: <Order />,
      },
    ],
  },
]);

const App = () => {
  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
      <ScrollButton />
    </div>
  );
};

export default App;
