import React from "react";
import { useNavigate } from "react-router-dom";

const BlogPageCard = ({ product }) => {
  const navigate = useNavigate();
  const _id = product.name;
  const idString = (_id) => {
    return String(_id).toLowerCase().split("").join("");
  };
  const rootId = idString(_id);
  const handleDetails = () => {
    navigate(`/blog/${rootId}`, {
      state: {
        item: product,
      },
    });
  };

  return (
    <div className="group rounded-md">
      <div
        onClick={handleDetails}
        className="w-full h-100 cursor-pointer overflow-hidden"
      >
        <img
          className="w-full h-60  object-cover filter grayscale hover:filter-none transition-transform duration-500 ease-in-out"
          src={product.image}
          alt="ProductImg"
        />
      </div>

      <div className="w-full border-[1px] px-2 bg-white pb-4">
        <div>
        <p className="font-subFont text-xs">{product.category}</p>
          <h2 className="text-md font-bold ">{product.title}</h2>
          <p  className="text-xs">August 19 2023</p>
        </div>
      </div>
    </div>
  );
};

export default BlogPageCard;



