import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PaymentOrder from "./PaymentOrder";
import Shipping from "./Shipping";
import Billing from "./Billing";
import AuthProvider, { useAuth } from "../context/AuthContext";
import Delivery from "./Delivery";

function Order() {
  const location = useLocation();
  const [showAddressForm, setShowAddressForm] = useState(false);
  const { isLoggedIn } = useAuth();

  const handleCheckboxChange = (e) => {
    setShowAddressForm(e.target.checked);
  };

  return (
    <div className="overflow-x-hidden w-full bg-[#edebe8] pb-20 pl-4 md:pl-0 pr-4 md:pr-0">
      <div className="max-w-screen-lg mx-auto py-10 bg-[#edebe8]">
        <h2 className="text-sm md:text-xl font-bold mt-24 md:mt-36 text-[#352214]">
          ORDER
        </h2>
        <div className="w-9 h-1 bg-[#352214]"></div>
      </div>

      {isLoggedIn  ? (
        <>
          <h2 className="md:max-w-screen-lg mx-auto text-sm text-green-500 font-bold mb-6">
            LOGGED IN
          </h2>
        </>
      ) : (
        <>
          <div className="max-w-screen-lg mx-auto bg-[#3522142E] p-4 text-xs mb-8">
            <h2>
              Already a customer?
              <Link to="/account">
                <span className="font-bold">Click here to Login</span>
              </Link>
            </h2>
          </div>
        </>
      )}

      <div className="max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-28 bg-[#edebe8]">
        <div className="w-full">
          {/* Display billing address form */}
          <Billing />

          <h2 className="text-sm font-bold mb-1">
            USEFUL INFORMATION FOR DELIVERY
          </h2>
          <Delivery />

          <div className="mb-4">
            <label className="block text-[#021228] text-sm font-bold mb-2">
              <input
                type="checkbox"
                className="mr-2 leading-tight"
                onChange={handleCheckboxChange}
              />
              SHIP TO A DIFFERENT ADDRESS?
            </label>

            {/* Display shipping address form */}
            {showAddressForm && <Shipping />}
          </div>
        </div>

        {/* Display payment order component */}
        <AuthProvider>
        <PaymentOrder />
        </AuthProvider>
      </div>
    </div>
  );
}

export default Order;
