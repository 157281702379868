import React from "react";

const ProductsExtra = () => {
  return (
    <div className="w-fit flex flex-col items-left gap-3 ">
      <h1 className=" text-sm md:text-xl py-2  text-[#352214] font-bold">
        CUSTOM FURNITURE
      </h1>
      <span
        className="text-start h-[1px] -translate-y-3 bg-[#352214]"
        style={{ width: "40px" }}
      ></span>

      <div className="pb-20">
        <div className="mb-5">
          <h2 className="text-[12px] md:text-sm font-semibold mb-5">
            ARTISTRY IN HANDCRAFTED FURNITURE BY WOODEN HERITAGE
          </h2>
          <p className="text-xs">
            At Wooden Heritage , we specialize in the design and handcrafting of
            custom furniture, including dining tables, coffee tables, office
            <br /> tables, workstations, and more. Every order is meticulously
            managed from start to finish by our dedicated Artmeta team. Our
            <br /> hallmark is true craftsmanship, where we bring your furniture
            vision to life just as you imagined it.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-[12px] md:text-sm font-semibold mb-5">
            ONLINE AUTHENTICATION AND WOOD HERITAGE
          </h2>
          <p className="text-xs">
            Our commitment to quality and authenticity is exemplified through
            our online authentication technology. You can easily verify the
            <br /> genuineness and quality of your product, complete with wood
            heritage information and a carbon dating certificate.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-[12px] md:text-sm font-semibold mb-5">
            THE EVOLUTION OF WOODEN HERITAGE
          </h2>
          <p className="text-xs">
            Originally rooted in metalwork, Wooden Heritage has expanded its
            expertise to encompass steel, aluminum, and stainless steel. <br />
            Within our collection, you'll discover a myriad of creations that
            seamlessly blend wood and metal. Driven by our passion for <br />
            innovation, we've ventured into crafting custom products tailored to
            your preferences.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-[12px] md:text-sm font-semibold mb-5">
            CHAMPIONING NOBLE MATERIALS
          </h2>
          <p className="text-xs">
            Noble materials hold a central place in our creative process. We
            collaborate with professionals who share our values, such as <br />
            renowned upholsterers and chair makers. This exchange of knowledge
            elevates our benches, cushions, chairs, and stools to new <br />
            heights of excellence.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-[12px] md:text-sm font-semibold mb-5">
            NIGERIAN ARTISANAL MANUFACTURING
          </h2>
          <p className="text-xs">
            Our small factory is a testament to craftsmanship, where we are
            unwavering in our commitment to producing robust furniture with
            <br />
            impeccable finishes. Quality remains our foremost priority, and our
            products are crafted to withstand the test of time. Our craftsmen
            <br />
            take their work seriously and execute it with perfection. Don't just
            take our word for it; consult our customers' opinions. Our workshop
            <br />
            is open for visitors by appointment, allowing you to witness the
            quality firsthand.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-[12px] md:text-sm font-semibold mb-5">
            ECO-RESPONSIBILITY AT THE CORE
          </h2>
          <p className="text-xs">
            When you choose Wooden Heritage, you're making a statement beyond
            acquiring furniture. You're enabling each member of our <br /> team
            to practice their craft passionately, preserving traditional French
            know-how. Opting for the short circuit demonstrates your <br />
            commitment to environmental conservation. With us, you order a
            unique, high-quality product, promoting sustainable consumption.
            <br />
            <br /> We are committed to eco-friendliness, protecting and
            delivering our creations with covers to minimize packaging waste.
            Our raw <br />
            materials are renewable and recyclable, underscoring our genuine
            commitment to ecological responsibility.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-[12px] md:text-sm font-semibold mb-5">INCOMPARABLE SERVICE</h2>
          <p className="text-xs">
            Unless specified otherwise, we provide internal furniture delivery
            throughout Nigeria. Our team is dedicated to offering you the best
            <br />
            service possible, with precise appointment scheduling, assistance
            during unloading, and careful handling of your furniture. Our <br />
            delivery personnel take the time to ensure the mission is completed
            to your satisfaction. There's no intermediate unloading in a<br />
            warehouse; it's a direct journey from our workshop to your home. For
            particularly heavy items, we can arrange delivery with two <br />
            fitters who handle the installation in your designated room, leaving
            you with nothing to do except open the door.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductsExtra;
