import React, { useEffect, useState } from "react";
import { pmask } from "../assets";
import { Link } from "react-router-dom";
import axios from "axios";

const Products = () => {
  const initialDisplayCount = 3;
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);
  const [showAll, setShowAll] = useState(false);
  const [productMainData, setProductMainData] = useState([]);

  useEffect(() => {
    axios
      .get("https://whew-api.apps.rokswood.com/v1/products?detailed=true%20")
      .then((response) => {
        const responseData = response.data.data;
        setProductMainData(responseData.items);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const updateDisplayCount = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 760) {  
      setDisplayCount(1);
    
    } else {    
      setDisplayCount(initialDisplayCount);
    }
  };

  useEffect(() => {
    updateDisplayCount();
    window.addEventListener("resize", updateDisplayCount);
    return () => {
      window.removeEventListener("resize", updateDisplayCount);
    };
  }, []);

  const handleLoadMore = () => {
    if (showAll) {
      setDisplayCount(initialDisplayCount);
    } else {
      setDisplayCount(productMainData.length);
    }
    setShowAll(!showAll);
  };


  return (
    <div
      className="py-1 pt-32 md:pt-52 pb-40 md:pb-60 -translate-y-56 bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${pmask})` }}
    >
      <div className="flex flex-col items-center gap-4 pl-4 md:pl-0 pr-4 md:pr-0">
        <p className="max-w-fit text-white text-xs md:text-normal text-center font-subFont">
          Crafting Dreams into Reality: Our Precision Furniture, Your
          Imagination
        </p>
      </div>

      <div className="max-w-screen-lg mx-auto py-4 grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 pl-20 md:pl-0 pr-20 md:pr-0">
        {productMainData.slice(0,displayCount).map((product) => (
          <div
            key={product.id} 
            className="group cursor-pointer rounded-md relative overflow-hidden"
          >
            <img
              className="w-full md:h-60  object-cover filter grayscale hover:filter-none transition-transform duration-500 ease-in-out"
              src={product.preview_image}
              alt="ProductImg"
            />

            <div className="w-full border-[1px] px-2 py-4 bg-white">
              <div>
                <h2 className="text-sm md:text-md font-bold ">{product.name}</h2>
              </div>

              <div>
                <p className="text-sm md:text-md">
                  ₦ {product.price}
                  <span className="font-thin text-xs"> Tax Included </span>
                </p>
              </div>
            </div>
          </div>
        ))}
        <div className="col-span-1 md:col-span-3 flex justify-center">
          <Link to="/product/All Products">
            <button
              className="col-span-1 text-center text-[8px] md:text-md py-3 text-white bg-gray opacity-100 border-2 rounded-md pl-5 pr-5 hover:bg-white hover:text-[#352214]"
              onClick={handleLoadMore}
            >
              {showAll ? "SHOW LESS" : "SEE OUR PRODUCTS"}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Products;
