import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Blogs = () => {
  const initialDisplayCount = 3;
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);
  const [showAll, setShowAll] = useState(false);
  const [blogHomeData, setBlogHomeData] = useState([]);
  useEffect(() => {
    fetch("https://whew-api.apps.rokswood.com/v1/posts")
      .then((response) => response.json())
      .then((data) => {
        if (data?.data?.items) {
          setBlogHomeData(data.data.items);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const updateDisplayCount = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 640) {
      setDisplayCount(1);
    } else if (screenWidth < 1024) {
      setDisplayCount(3);
    } else {
      setDisplayCount(initialDisplayCount);
    }
  };

  useEffect(() => {
    updateDisplayCount();
    window.addEventListener("resize", updateDisplayCount);
    return () => {
      window.removeEventListener("resize", updateDisplayCount);
    };
  }, []);

  const handleLoadMore = () => {
    if (showAll) {
      setDisplayCount(initialDisplayCount);
    } else {
      setDisplayCount(blogHomeData.length);
    }
    setShowAll(!showAll);
  };

  return (
    <div className="bg-white -translate-y-60 md:-translate-y-60 lg:-translate-y-60 pl-2 md:pl-0 lg:pl-0 pr-2 md:pr-0 lg:pr-0">
      <div className="flex flex-col items-center gap-3 ">
        <h1 className="text-md md:text-2xl text-center py-2 w-80 text-[#352214] font-bold">
          BLOGS
        </h1>

        <span
          className="text-center -translate-y-4 h-[1px] bg-[#352214] w-[80px]"
        ></span>
      </div>

      <div className="max-w-screen-lg mx-auto py-4 grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 pl-20 md:pl-0 pr-20 md:pr-0">
        {blogHomeData.slice(0, displayCount).map((product) => (
          <div
            key={product.id}
            className="group cursor-pointer rounded-md relative overflow-hidden"
          >
            <img
              className="w-full h-40 object-cover  text-xs md:text-md group-hover:scale-110 duration-500"
              src={product.image}
              alt={product.title}
            />

            <div className="w-full border-[1px] px-2 py-4 bg-white">
              <div>
                <h2 className=" text-sm md:text-md font-bold ">{product.category}</h2>
              </div>
              <div>
                <h2 className=" text-sm md:text-md font-bold ">{product.title}</h2>
              </div>
              <div>
                <h2 className=" text-[10px] md:text-md">August 19 2023</h2>
              </div>
            </div>
          </div>
        ))}
        <div className="col-span-1 md:col-span-3 flex justify-center">
          <Link to="/blogPage">
            <button
              className="col-span-1 text-center text-[8px] md:text-md py-3 text-white bg-gray opacity-100 border-2 rounded-md pl-5 pr-5 hover:bg-white bg-[#352214] hover:text-[#352214]"
              onClick={handleLoadMore}
            >
              {showAll ? "SHOW LESS" : "VIEW ALL"}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
