import React, { useEffect, useState } from "react";
import Banner from "../components/Banner";
import Products from "../product/Products";
import { useLoaderData } from "react-router-dom";
import MasterPiece from "../components/MasterPiece";
import BeforeFooter from "../components/BeforeFooter";
import LiveChat from "../chat/LiveChat";
import Blogs from "../blog/Blogs";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import Preferences from "../components/Preferences";

const Home = () => {
  const [isPreferencesVisible, setIsPreferencesVisible] = useState(false);
  const togglePreferences = () => {
    setIsPreferencesVisible(!isPreferencesVisible);
  };
  const hasUserConsentedToCookies = true;

  return (
    <div>
      <Banner />
      <MasterPiece />
      <Products />
      <Blogs />
      <BeforeFooter />
      <LiveChat />

      {hasUserConsentedToCookies ? null : (
        <CookieConsent
          debug={true}
          location="bottom"
          style={{ background: "white", zIndex: "50" }}
          buttonStyle={{
            background: "#352214",
            color: "white",
            fontSize: "14px",
          }}
          buttonText="ACCEPT"
          expires={365}
        >
          <p className="text-[#352214] text-sm">
            By clicking Accept, you agree to the storing of cookies on your
            device to enhance site navigation, analyse site usage. View our{" "}
            <Link to="/privacy"> Privacy Policy</Link> for more information.
            <span
              className="float-right font-bold cursor-pointer"
              onClick={togglePreferences}
            >
              Preference
              {isPreferencesVisible && (
                <Preferences onClose={togglePreferences} />
              )}
            </span>
          </p>
        </CookieConsent>
      )}

      <LiveChat />
    </div>
  );
};

export default Home;
