import React, { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementQuantity,
  deleteItem,
  incrementQuantity,
} from "../redux/rwoodSlice";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

const CartItem = () => {
  const dispatch = useDispatch();
  const productData = useSelector((state) => state.rwood.productData);
  return (
    <div className="w-4/4 flex flex-col pl-4 md:pl-0 pr-4 md:pr-0">
      <div className="w-fit mt-24 md:mt-36 pb-10 ">
        <h2 className="text-sm md:text-xl font-semibold text-[#352214]">
          {" "}
          BASKET
        </h2>
        <div className="w-9 h-1 bg-[#352214]"></div>
      </div>

      <div className="bg-gray-100 text-black text-xs font-bold border-b border-black pt-10 md:pt-5 pb-10 md:h-10 md:flex flex-auto gap-10 lgl:grid grid-cols-5 pl-4 md:pl-0">
        <div className="mx-auto">PRODUCT</div>

        <div className="md:flex mx-auto gap-12 md:gap-36 ">
          <div>PRICE</div>
          <div>QUANTITY</div>
          <div>TOTAL INCL. VAT</div>
        </div>
      </div>
      <div className="bg-white text-black pb-10 md:pb-10 pt-10 md:pt-2  table-row-group">
        {productData.map((item) => (
          <div
            key={item.id}
            className="md:flex items-center border-b border-black pt-2 justify-between ml-10 mr-20 pb-6"
          >
            <div className="md:flex items-center gap-2">
              <MdOutlineClose
                onClick={() =>
                  dispatch(deleteItem(item._id)) &
                  toast.error(`${item.title} is removed`)
                }
                className="text-xl text-gray-600 hover:text-red-600 cursor-pointer duration-300"
              />
              <img
                className="w-12 h-12 object-cover"
                src={item.image}
                alt="productImg"
              />
              <div>
                <h2 className="w-80 text-sm font-bold">{item.title}</h2>
                <p className="w-80 text-xs font-semibold">
                  See product description
                </p>
              </div>
            </div>

            <div className="md:flex mx-auto ">
              <p className="text-xs transform md:-translate-x-32 md:translate-y-3">
                ₦{item.price}
              </p>

              <div className="w-52 md:flex items-center text-xs transform md:-translate-x-10 justify-between text-gray-500 gap-4 border p-3">
                <p className="text-sm">Quantity</p>
                <div className="flex items-center gap-4 text-sm font-semibold">
                  <span
                    onClick={() =>
                      dispatch(
                        decrementQuantity({
                          _id: item._id,
                          title: item.title,
                          image: item.preview_image,
                          price: item.price,
                          quantity: 1,
                          description: item.description,
                        })
                      )
                    }
                    className="border h-5 font-normal text-lg flex items-center
                  justify-center px-2 hover:bg-gray-700 hover:text-white
                  cursor-pointer duration-300 active:bg-black"
                  >
                    -
                  </span>
                  {item.quantity}
                  <span
                    onClick={() =>
                      dispatch(
                        incrementQuantity({
                          _id: item._id,
                          title: item.title,
                          image: item.image,
                          price: item.price,
                          quantity: 1,
                          description: item.description,
                        })
                      )
                    }
                    className="border h-5 font-normal text-lg flex items-center
                  justify-center px-2 hover:bg-gray-700 hover:text-white
                  cursor-pointer duration-300 active:bg-black"
                  >
                    +
                  </span>
                </div>
              </div>
            </div>
            <p className="text-xs md:-translate-x-16 md:translate-y-1">
              ₦{item.quantity * item.price}
            </p>
          </div>
        ))}
        <Link to="/product/All Products">
          <div className="transform md:-translate-x-16">
            <button className="bg-[#352214] text-white w-32 h-8 -translate-x-8 translate-y-4 rounded-sm float float-right text-sm">
              Update Cart
            </button>
          </div>
        </Link>
      </div>

      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default CartItem;
