import React from 'react'
import BannerAbout from '../components/BannerAbout'
import BeforeFooterAbout from '../components/BeforeFooterAbout'
import MidAbout from '../components/MidAbout'

const About = () => {
  return (
    <div>
      <BannerAbout/>
      <MidAbout/>
      <BeforeFooterAbout/>
    </div>
  )
}

export default About