import React from "react";
import { mid1, mid2 } from "../assets";

const MidAbout = () => {
  return (
    <div className="bg-[#EDEBE8] -translate-y-10 md:-translate-y-32 md:h-72">
      <div className="py-1 bg-[#EDEBE8]">
        <div className="max-w-screen-lg mx-auto grid translate-y-20 md:translate-y-0 md:grid-cols-2 -mb-8 md:pt-28 bg-[#EDEBE8]">
          <div className="flex flex-col gap-4 -translate-y-52 pl-4 md:pl-0 pr-4 md:pr-0">
            <div className="gap-20">
              <p className="text-[12px] md:text-sm">
                At Wooden Heritage, we are the embodiment of precision meeting
                personalization, where the essence of artistry melds seamlessly
                with the world of custom-made furniture. Our journey is one of
                passion and craftsmanship, a dedication to redefining the way
                you experience and interact with your living spaces.
              </p>
            </div>

            <div>
              <h2 className="font-bold text-sm md:text-base mb-2 ">
                CRAFTING YOUR VISION
              </h2>
              <p className="text-[12px] md:text-sm">
                At Wooden Heritage, we don't just design furniture; we craft
                your vision into reality. Every piece we create is an embodiment
                of your preferences, needs, and desires. From the selection of
                the finest materials to the meticulous attention to detail, we
                ensure that your custom-made furniture is a true work of art.
              </p>
            </div>

            <div>
              <h2 className="font-bold text-sm md:text-base mb-2 ">MADE JUST FOR YOU</h2>
              <p className="text-[12px] md:text-sm">
                When you choose Wooden Heritage, you're choosing exclusivity.
                Each piece of furniture is made to order, ensuring that it
                perfectly fits your space and complements your aesthetic. No two
                pieces are the same, reflecting the uniqueness of your taste.
              </p>
            </div>

            <div>
              <h2 className="font-bold text-sm md:text-base mb-2 ">
                SUSTAINABILITY AT HEART
              </h2>
              <p className="text-[12px] md:text-sm">
                Our commitment to craftsmanship extends to our dedication to
                sustainability. We prioritize eco-friendly practices, using
                renewable and recyclable materials whenever possible. We believe
                in creating furniture that not only stands the test of time but
                also leaves a lasting positive impact on the environment.
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-5 items-center -translate-y-36 md:-translate-y-24 float-right ">
            <div className="group cursor-pointer relative overflow-hidden">
              <img
                className="w-96 h-32 md:h-60 object-cover rounded-md group-hover:scale-110 duration-500"
                src={mid1}
                alt="img1"
              />
            </div>

            <div className="group cursor-pointer relative translate-x-16 overflow-hidden">
              <img
                className="w-60 h-24 md:h-32 object-cover rounded-md group-hover:scale-110 duration-500"
                src={mid2}
                alt="img2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MidAbout;
