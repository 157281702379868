import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const MasterPiece = () => {
  const [masterpieceData, setMasterpieceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(
      "https://whew-api.apps.rokswood.com/v1/product_categories?detailed=true"
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.data?.items) {
          setMasterpieceData(data.data.items);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="py-1 pb-32 bg-[#edebe8] -translate-y-20 pl-12 md:pl-0 pr-12 md:pr-0 pt-16 md:pt-0">
      <div className="flex flex-col items-center gap-3 ">
        <h1
          className="text-md md:text-2xl text-center py-2 w-80 text-[#352214] font-bold"
          style={{ fontFamily: "architect-daughter" }}
        >
          OUR MASTERPIECES
        </h1>
        <p className="max-w-fit text-[#352214] text-center text-[12px] md:text-md font-subFont">
          Crafting Dreams into Reality: Our Precision Furniture, Your
          Imagination
        </p>
        <span
          className="text-center h-[1px] bg-[#352214]"
          style={{ width: "80px" }}
        ></span>
      </div>

      <div className="max-w-screen-lg mx-auto py-10 grid grid-cols-2 md:grid-cols-3 gap-10">
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          masterpieceData.map((product) => (
            <div
              key={product.id}
              className="group cursor-pointer rounded-md relative overflow-hidden"
            >
              <img
                className="w-full h-40 object-cover group-hover:scale-110 duration-500"
                src={product.image}
                alt={product.name}
              />
              <Link to={`/product/${product.name}`}>
                <div className="absolute bottom-0 left-0 right-0 bg-black opacity-70 text-white p-2">
                  <p className="md:ml-5 text-[10px] md:text-md">{product.name}</p>
                </div>
              </Link>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MasterPiece;
