import React from "react";
import FooterLinks from "../components/Reusables";

const WarrantyPolicy = () => {
  return (
    <div className="w-full bg-[#edebe8] pl-4 md:pl-0 pr-4 md:pr-0">
      <div className="max-w-screen-lg mx-auto py-10 bg-[#edebe8]">
        <h2 className="text-sm md:text-xl font-bold mt-24 md:mt-36 text-[#352214]">
          WARRANTY POLICY
        </h2>
        <div className="w-9 h-1 bg-[#352214]"></div>
      </div>
      <div className="max-w-screen-lg mx-auto  bg-[#edebe8] text-sm gap-5 flex flex-col pb-20">
        <FooterLinks />
       
      </div>
    </div>
  );
};

export default WarrantyPolicy;
