const FooterLinks = () => {
  return (
    <div>
      <h2 className="max-w-screen-lg ">1. Documentation</h2>
      <p className="max-w-screen-lg ">
        Effective Date: [Date] <br />
        <br />
        1. Acceptance of Terms <br />
        By using the Wooden Heritage e-Commerce website ("Website") and placing
        an order for Classic Furniture products ("Products"), you agree to abide
        by these Terms of Sale. These terms govern the sale and delivery of our
        Products, so please read them carefully. <br />
        <br />
        2. Orders and Pricing <br />
        <br />
        2.1. Product Availability <br />
        <br />
        All orders are subject to availability. We strive to keep our inventory
        up to date, but if a Product you have ordered is not available, we will
        notify you as soon as possible and offer alternatives or a refund.{" "}
        <br />
        <br />
        2.2. Pricing <br />
        <br />
        Prices for Products are listed on the Website and are subject to change
        without notice. The price displayed at the time of your order is the
        price you will be charged. <br />
        <br />
        2.3. Taxes <br />
        <br />
        Prices listed do not include applicable taxes. You are responsible for
        any sales tax, use tax, or other taxes imposed by your jurisdiction.
        <br />
        <br />
        2.4. Payment <br />
        <br />
        We accept various payment methods, including credit cards, debit cards,
        bank transfer and online payment platforms. Payment is required at the
        time of order placement.
        <br />
        <br />
        3. Shipping and Delivery <br />
        <br />
        3.1. Shipping Methods <br />
        <br />
        We offer various shipping options, and the available methods are
        displayed during the checkout process. You may choose the method that
        best suits your needs. <br />
        <br />
        3.2. Shipping Costs <br />
        <br />
        Shipping costs are calculated based on the shipping method selected, the
        destination, and the size and weight of the Products. You will see the
        shipping cost during checkout. <br />
        <br />
        3.3. Delivery Time <br />
        <br />
        We make every effort to ship your order promptly. Delivery times may
        vary based on your location and the selected shipping method. We will
        provide an estimated delivery date during checkout.
        <br />
        <br />
        3.4. Delivery Address <br />
        <br />
        You are responsible for providing accurate and complete shipping
        information. We will not be liable for any delays or misdeliveries due
        to incorrect addresses.
        <br />
        <br />
        4. Returns and Refunds <br />
        <br />
        4.1. Return Policy <br />
        <br />
        We want you to be satisfied with your purchase. If you are not
        completely satisfied with a Product, you may request a return within 3
        business days from the date of delivery. Please refer to our Return
        Policy on the Website for detailed instructions. <br />
        <br />
        4.2. Refunds <br />
        <br />
        Refunds will be issued in accordance with our Return Policy. Refunds
        will be processed within 7 business days after we receive the returned
        Product in its original condition.
        <br />
        <br />
        5. Warranty
        <br />
        <br />
        5.1. Product Warranty
        <br />
        <br />
        Wooden Heritage warrants that its Products are free from defects in
        materials and workmanship for a period of 1 year from the date of
        purchase. Please refer to our Warranty Policy on the Website for
        detailed information.
        <br />
        <br />
        6. Privacy <br />
        <br />
        6.1. Data Protection <br />
        <br />
        We respect your privacy and handle your personal information in
        accordance with our Privacy Policy. By using our Website and placing
        orders, you consent to the collection and use of your personal
        information as described in our Privacy Policy.
        <br />
        <br />
        7. Contact Information
        <br />
        <br />
        7.1. Customer Support
        <br />
        <br />
        For inquiries, assistance, or concerns, please contact our Customer
        Support team via the contact information provided on the Website.
        <br />
        <br />
        8. Changes to Terms <br />
        <br />
        8.1. Modification
        <br />
        <br /> Wooden Heritage reserves the right to modify these Terms of Sale
        at any time. Any changes will be posted on the Website, and it is your
        responsibility to review them periodically. Your continued use of the
        Website after changes are posted constitutes acceptance of the updated
        Terms of Sale. By completing your purchase on the Wooden Heritage
        e-Commerce Website, you acknowledge that you have read, understood, and
        agree to these Terms of Sale. These terms constitute a legally binding
        agreement between you and Wooden Heritage.
        <br />
        <br />
        Thank you for choosing Wooden Heritage for your Classic Furniture needs.
      </p>
    </div>
  );
};

export default FooterLinks;
