import React from "react";
import FooterLinks from "../components/Reusables";
import { Link } from "react-router-dom";

const Faq = () => {
  return (
    <div className="w-full bg-[#edebe8] pl-4 md:pl-0 pr-4 md:pr-0 overflow-x-hidden">
      <div className="max-w-screen-lg mx-auto py-10 bg-[#edebe8]">
        <h2 className="text-sm md:text-xl font-bold mt-24 md:mt-36 text-[#352214]">FAQs/SUPPORT</h2>
        <div className="w-9 h-1 bg-[#352214]"></div>
      </div>
      <div className="max-w-screen-lg mx-auto  bg-[#edebe8] text-sm gap-5 flex flex-col pb-20">
        <FooterLinks />

        <div className="w-fit md:w-[470px] bg-[#e0e0e0] translate-y-4 p-10 text-sm">
          <p className="pb-5">
            If none of the above answers my questions, please contact support
            for further enquiry and support needed. Our support is always eager
            and ready to help out.
          </p>
          <div className="flex gap-10 pb-5">
            <div className="flex flex-col">
              <p>Call Support</p>
              <p className="font-bold">+234 700 000 0000</p>
            </div>
            <div>
              <p>Email Support</p>
              <p className="font-bold">support@woodenheritage.com</p>
            </div>
          </div>
          <p className="pb-5">Or</p>

            <Link to = '/contactUs'>
          <div  className="pb-5">
          <button
            className="bg-[#352214] text-white rounded-sm w-32 h-10 text-sm">
          Fill Contact Form
          </button>
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Faq;
