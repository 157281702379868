import React from "react";

const RefundFile = () => {
  return (
    <div>
      <h2 className="max-w-screen-lg ">1. Documentation</h2>
      <p className="max-w-screen-lg ">
        Effective Date: [Date] <br />
        <br />
        At Wooden Heritage, we strive to provide you with high-quality Classic Furniture products and exceptional customer service. We want you to be completely 
        satisfied with your purchase. This Refund Policy outlines the terms and conditions under which refunds may be issued for products purchased through our e-
        Commerce Website.
        <br/> <br/>
        1. Returns and Refunds 
        <br /><br/>
        1.1. Return Eligibility
        <br /><br/>
        You may request a return and refund for a Product purchased on our Website within 3 business days from the date of delivery. To be eligible for a return and 
        refund, the Product must be in its original condition, unused, and in its original packaging.  
        <br /> <br />
        1.2. Non-Returnable Items <br />
        <br />
        Some Products are not eligible for return and refund, including: 
        Products that have been customized or made to order. 
        Products that have been assembled or modified. 
        Products that have been used, damaged, or are not in their original condition.
        <br/> <br/>
        1.3. Return Process <br />
        <br />
        To initiate a return, please contact our Customer Support team via the contact information provided on our Website. We will provide you with instructions 
        on how to return the Product.
        <br /> <br />
        1.4. Refund <br />
        <br />
        Once we receive and inspect the returned Product, we will process the refund. Refunds will be issued to the original payment method used for the purchase. 
        Please allow 7 business days for the refund to be processed after we receive the returned Product. <br />
        <br />
        2. Damaged or Defective Products <br />
        <br />
        2.1 Inspection <br />
        <br />
        We carefully inspect and package our Products before shipping to ensure they are in good condition. However, if you receive a damaged or defective 
        Product, please contact us within 3 business days from the date of delivery.
        <br /> <br />
        2.2. Replacement or Refund  <br />
        <br />
        If you receive a damaged or defective Product, we will offer you the option of a replacement or a full refund, including any shipping charges.
        <br />   <br />
        3. Cancellation of Orders <br />
        <br />
        3.1. order Cancellation <br />
        <br />
        You may cancel your order for a full refund before the Product has been shipped. Once the Product has been shipped, the return and refund process 
        outlined in Section 1 will apply. <br />
        <br />
        4. Contact Us  <br />
        <br />
        If you have any questions or concerns about our Refund Policy or need assistance with a return or refund, please contact our Customer Support team via 
        the contact information provided on our Website. <br />
        <br />
        5. Changes to Refund Policy  <br />
        <br />
        We may update this Refund Policy to reflect changes in our practices or legal requirements. We will post any changes on our Website, and the updated 
        policy will be effective when posted. 
        <br /> <br />        
        By making a purchase on the Wooden Heritage e-Commerce Website, you acknowledge and agree to the terms and conditions of this Refund Policy. 
        <br />
        Thank you for choosing Wooden Heritage. Your satisfaction is important to us.        
      </p>
    </div>
  );
};

export default RefundFile;
