import React, { useState } from "react";

const Delivery = () => {
  const [accommodationType, setAccommodationType] = useState("");
  const [elevatorForm, setElevatorForm] = useState(false);
  const [otherForm, setOtherForm] = useState(false);

  const handleCheckboxChange1 = (e) => {
    setElevatorForm(e.target.checked);
  };

  const handleCheckboxChange2 = (e) => {
    setOtherForm(e.target.checked);
  };
  return (
    <div>
      <form onSubmit={(event) => event.preventDefault()}>
        <div className="mb-4">
          <input
            className="shadow appearance-none border cursor-pointer 
                first-letter:rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="accommodationName"
            type="text"
            placeholder="Type of accommodation (Optional)"
            required
            onChange={(event) => setAccommodationType(event.target.value)}
          />
        </div>

        <div className="mb-4">
          <input
            className="shadow appearance-none border cursor-pointer 
                first-letter:rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="levelName"
            type="text"
            placeholder="Level (Optional)"
            required
          />
        </div>

        <div className="mb-4">
          <input
            className="shadow appearance-none border cursor-pointer 
                first-letter:rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="floorName"
            type="text"
            placeholder="Which floor? (Optional)"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-[#021228] text-xs font-sm">
            <input
              type="checkbox"
              className="mr-2 leading-tight"
              onChange={handleCheckboxChange1}
            />
            There is an elevator (Optional)
          </label>
          {elevatorForm && (
            <div className="mb-4">
              <input
                className="shadow appearance-none border cursor-pointer 
                first-letter:rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="floorName"
                type="text"
                placeholder="Elevator"
                required
              />
            </div>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-[#021228] text-xs font-sm">
            <input
              type="checkbox"
              className="mr-2 leading-tight"
              onChange={handleCheckboxChange2}
            />
            There are particular difficulties with access (please provide
            details below) - optional
          </label>
          {otherForm && (
            <div className="mb-4">
              <input
                className="shadow appearance-none border h-10 cursor-pointer 
                first-letter:rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="floorName"
                type="text"
                placeholder="Elevator"
                required
              />
            </div>
          )}
        </div>

        <div className="mb-12 border border-black p-2">
          <label className="block text-[#021228] text-xs font-sm">
            <input type="checkbox" className="mr-2 border leading-tight" />I
            have checked that my access allows the delivery of my furniture *
          </label>
        </div>
      </form>
    </div>
  );
};

export default Delivery;