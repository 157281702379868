import React, { useEffect, useState } from "react";
import CartItem from "../components/CartItem";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { emptyCart } from "../assets";

const Cart = () => {
  const productData = useSelector((state) => state.rwood.productData);
  const [totalAmt, setTotalAmt] = useState(0);

  useEffect(() => {
    let price = 0;
    productData.map((item) => {
      price += item.price * item.quantity;
      return price;
    });
    setTotalAmt(price);
  }, [productData]);

  const isCartEmpty = productData.length === 0;

  return (
    <div className="overflow-x-hidden w-full bg-[#edebe8] pb-52">
      <div className="max-w-screen-lg mx-auto py-10 bg-[#edebe8]">
      {isCartEmpty ? (
       <img src={emptyCart} alt="emptyCartPng" className="absolute mt-40 pb-12 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />

      ) : (
        <div>
        <CartItem />

        <div>
          <div className="w-full pl-20 bg-[#edebe8] md:translate-x-24 translate-y-12">
            <h2 className="text-sm md:text-xl font-medium">TOTAL BASKET</h2>
            <div className="w-9 h-1  bg-[#352214]"></div>

            <div>
              <div className="md:w-1/2 mx-auto translate-y-12 h-16 items-center pr-4 md:pr-0 pl-4 md:pl-0">
                <div className="">
                  <div className="flex flex-row justify-between border-2 border-black">
                    <p className="items-center  bg-[#F8F8F8] pt-8 border-2 border-t-0 border-l-0 border-black border-b-0 py-1.5 text-md px-4 font-bold ">
                      SUBTOTAL
                    </p>
                    <span className="font-bold  text-black text-sm pr-40 pt-8">
                      ₦{totalAmt}
                    </span>
                  </div>
                  <Link
                    to={`/order?subtotal=${totalAmt}&cartData=${JSON.stringify(productData)}`}
                    cartData={productData}
                  >
                    <button
                      className="md:w-fit float-right p-5 pt-2 h-8 bg-[#352214] text-white text-xs translate-y-2 md:translate-y-4 
                      rounded-sm hover:bg-white hover:text-[#352214] duration-30"
                    >
                      <p className="text-center">CHECKOUT CART</p>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      )}
        
      </div>
    </div>

  );
};

export default Cart;

