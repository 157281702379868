import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addToCart } from "../redux/rwoodSlice";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const CategoriesCard = ({ product }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const Location = useLocation();

  const navigate = useNavigate();

  const _id = product.title;
  const idString = (_id) => {
    return String(_id).toLowerCase().split("").join("");
  };
  const rootId = idString(_id);
  const handleDetails = () => {
    navigate(`/products/${rootId}`, {
      state: {
        item: product,
      },
    });
  };

  useEffect(() => {
    if (Location.state && Location.state.item) {
      setDetails(Location.state.item);
    }
  }, [Location.state]);

  let numColumns = "grid-cols-3"; 
      if (window.innerWidth <= 768) {
        numColumns = "grid-cols-2"; 
      }
      if (window.innerWidth <= 480) {
        numColumns = "grid-cols-1"; 
      }
  return (
    <div className={`w-full ${numColumns}`}>
      <div
        onClick={handleDetails}
        className="cursor-pointer overflow-hidden"
      >
        <img
          className="h-40 md:h-60 w-full object-cover filter grayscale hover:filter-none transition-transform duration-500 ease-in-out"
          src={product.preview_image}
          alt="ProductImg"
        />
      </div>

      <div className="border-[1px] px-2 bg-white pb-4">
        <div>
          <h2 className="text-md font-bold ">{product.name}</h2>
        </div>

        <div>
          <p className="text-md">₦ {product.price} <span className="font-thin text-xs"> Tax Included </span></p>
        </div>

        
          <button
            onClick={() =>
              dispatch(
                addToCart({
                  _id: product.id,
                  title: product.name,
                  image: product.preview_image,
                  price: product.price,
                  quantity: product.quantity||1,
                  description: product.description,
                })
              ) & toast.success(`${product.name} is added`)
            }
            className="bg-[#352214] h-6 w-full text-white text-xs rounded-sm"
          >
            Add to cart
          </button>
        </div>
      

      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default CategoriesCard;
