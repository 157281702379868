import React, { useState } from "react";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { mainv2, main1v2, Group3 } from "../assets";
import { Link } from "react-router-dom";

const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const data = [mainv2, main1v2, Group3];
  const slides = [
    {
      image: data[0],
      text: "CLASSIC FURNITURE MANUFACTURER",
      textLabel: "Personalize your piece down to the smallest details",
      buttonLabel1: "VIEW THE COLLECTION",
    },
    {
      image: data[1],
      text: "CRAFTED WITH THE UTMOST PRECISION BY HAND",
      textLabel:
        "personalized down to the smallest details, ensuring it's uniquely yours",
      buttonLabel1: "VIEW THE COLLECTION",
    },
    {
      image: data[2],
      text: "CRAFT MEETS COMFORT",
      textLabel: "Experience the Luxury of Custom Precision Furniture",
      buttonLabel1: "VIEW THE COLLECTION",
    },
  ];

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
  };

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  };

  return (
    <div className="overflow-y-hidden w-full h-auto overflow-x-hidden bg-[#edebe8] -translate-y-20">
      <div className="w-screen h-50vh relative">
        <div
          style={{
            transform: `translateX(-${currentSlide * 100}vw)`,
            transition: "transform 1s ease",
          }}
          className="w-fit h-full flex transition-transform duration-1000 flex-1 
          relative ease-out"
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="relative w-screen h-full flex mt-[140px] md:-mt-[100px] items-center justify-center"
            >
              <img
                className="w-screen h-full object-cover"
                src={slide.image}
                alt={`img-${index}`}
              />

              <div className="absolute z-30 text-center md:top-1/2 md:transform translate-x-6/7">
                <p className="text-white font-bold text-[12px] md:text-2xl">
                  {slide.text}
                </p>
                <p className=" text-white text-[14px] md:text-lg font-subFont pl-4 md:pl-0 pr-4 md:pr-0">
                  {slide.textLabel}
                </p>
                <Link to="/product/All Products">
                <button 
                
                className="border border-1 p-3 pr-6 pl-6 rounded-lg md:mt-10 hover:bg-white hover:text-[#352214] text-white cursor-pointer">
                  <p className="z-30 text-[6px] md:text-sm">{slide.buttonLabel1}</p>
                </button>
                </Link>
              </div>
            </div>
          ))}
        </div>

        <div
          className="absolute w-full flex justify-center items-center gap-72 md:gap-[1000px] bottom-64
        text-slate-400 translate-y-[400px] md:translate-y-40"
        >
          <div
            className="flex items-center justify-center 
                hover:cursor-pointer duration-300 mb-[300px] text-[22px] md:text-[30px]"
            
            onClick={prevSlide}
          >
            <MdOutlineArrowBackIos />
          </div>

          <div
            className="flex items-center justify-center 
                hover:cursor-pointer duration-300 mb-[300px] text-[22px] md:text-[30px]"
            
            onClick={nextSlide}
          >
            <MdOutlineArrowForwardIos />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
