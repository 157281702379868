import axios from "axios";
import React, { useEffect, useState } from "react";
import SimilarProductCard from "./SimilarProductCard";


const SimilarProduct = () => {
    const [products, setProducts] = useState([])
   
    useEffect(()=>{
    axios
      .get("https://whew-api.apps.rokswood.com/v1/products?detailed=true%20")
      .then((response) => {
        const responseData = response.data.data;
        setProducts(responseData.items);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  
  return (
    <div>
      <div className="pt-12 pl-8 md:pl-0 pr-8 md:pr-0">
        <div className="flex flex-col items-center gap-3">
          <h1
            className="text-2xl text-center py-2 w-80 text-[#352214] font-bold"
            style={{ fontFamily: "architect-daughter" }}
          >
            SIMILAR PRODUCTS
          </h1>
          <span
            className="text-center h-[1px] bg-[#352214]"
            style={{ width: "80px" }}
          ></span>
        </div>

      

        <div className="max-w-screen-lg mx-auto py-10 grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-12 md:gap-10">
        {products.slice(0, 3).map((item) => (
          <SimilarProductCard key={item.id} product={item} />
        ))}
        </div>
      </div>
    </div>
  );
};

export default SimilarProduct;
