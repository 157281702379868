import React from "react";
import { aboutp2 } from "../assets";
import { Link } from "react-router-dom";

const BannerAbout = () => {
  const data = [aboutp2];
  const slides = [
    {
      image: data[0],
      text: "WHO ARE WE?",
      textLabel:
        "Precision Meets Personalization: Unveil the Artistry of Custom-Made Furniture",
      buttonLabel1: "VIEW THE COLLECTION",
    },
  ];

  return (
    <div className="w-full h-auto overflow-x-hidden bg-[#EDEBE8] pb-32 -translate-y-10 md:-translate-y-20">
      <div className="w-screen relative bg-[#EDEBE8] ">
        <div
          className="w-fit h-full flex transition-transform duration-1000 flex-1 
          relative ease-out"
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="relative w-screen h-full flex items-center mt-[120px] md:-mt-[100px] justify-center"
             
            >
              <img
                className="w-screen h-full object-cover"
                src={slide.image}
                alt={`img-${index}`}
              />

              <div className="absolute text-center z-10 md:top-1/2 md:transform translate-x-6/7">
                <p className="text-white font-bold text-[12px] md:text-2xl">{slide.text}</p>
                <p className="text-white text-[14px] md:text-lg font-subFont pl-4 md:pl-0 pr-4 md:pr-0">
                  {slide.textLabel}
                </p>
                <Link to="/product/All Products">
                <button className="border border-1 p-3 pr-6 pl-6 rounded-lg md:mt-10 hover:bg-white hover:text-[#352214] text-white ">
                  <p className="text-[6px] md:text-sm">{slide.buttonLabel1}</p>
                </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BannerAbout;
