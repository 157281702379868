import React from "react";
import { useNavigate } from "react-router-dom";

const SimilarProductCard = ({ product }) => {
  const navigate = useNavigate();
  const _id = product.name;
  const idString = (_id) => {
    return String(_id).toLowerCase().split("").join("");
  };
  const rootId = idString(_id);
  const handleDetails = () => {
    navigate(`/products/${rootId}`, {
      state: {
        item: product,
      },
    });
  };
  return (
    <div className="group">
      <div
        onClick={handleDetails}
        className="w-full h-100 cursor-pointer overflow-hidden"
      >
        <img
          className="w-full h-60  object-cover filter grayscale hover:filter-none transition-transform duration-500 ease-in-out"
          src={product.preview_image}
        alt={product.price}
        />
      </div>

      <div className="w-full border-[1px] px-2 bg-white">
        <div>
          <h2 className="text-base font-bold ">{product.name}</h2>
        </div>

        <div>
          <p>{product.category}</p>
        </div>
      </div>
    </div>
  );
};

export default SimilarProductCard;
