import React from "react";
import { ContactMe, review } from "../assets";
import { Link } from "react-router-dom";

const CardProduct = () => {
  return (
    <div>
      <div className="max-w-screen-lg mx-auto bg-neutral-200 py-8 flex">
        <div className="group">
          <img src={ContactMe} alt="contactImg" className="w-40 h-20" />

          <Link to="/contactUs">
            <button className="text-center text-xs translate-x-6 rounded-sm w-32 h-8 bg-[#352214] text-white">
              Contact Us
            </button>
          </Link>
        </div>

        <div className="group">
          <img
            src={review}
            alt="contactImg"
            className="w-28 h-20 translate-x-8"
          />

          <button className="text-center text-xs translate-x-6 rounded-sm w-32 h-8 bg-[#352214] text-white">
            See Reviews
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardProduct;
