import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PrintIcon from "@mui/icons-material/Print";
import DescriptionIcon from "@mui/icons-material/Description";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import { craftPic, deliveryPic, designPic, fabricationPic, sustainPic } from "../assets";

const HorizontalAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [certificateUrls, setCertificateUrls] = useState({});
  const [productInformation, setProductInformation] = useState("");
const [craftsmanshipInsight, setCraftsmanshipInsight] = useState("");
const [design, setDesign] = useState("");
const [delivery, setDelivery] = useState("");
const [craftsmanship, setCraftsmanship] = useState("");
const [fabrication, setFabrication] = useState("");
const [sustainableChoice, setSustainableChoice] = useState("");
const [reviews, setReviews] = useState([]);

useEffect(() => {
  axios
    .get("https://whew-api.epicsprint.tech/v1/reviews?detailed=true")
    .then((response) => {
      setReviews(response.data.data.items);
    })
    .catch((error) => {
      console.error("Error fetching reviews:", error);
    });
}, []);


  useEffect(() => {
    axios
      .get("https://whew-api.epicsprint.tech/v1/products?detailed=true")
      .then((response) => {
        const products = response.data.data.items;

      
        const authenticityCertificate = products[0].authenticity_certificate;
        const carbonDatingCertificate = products[0].carbon_dating_certificate;

           setProductInformation(products[0].information);
      setCraftsmanshipInsight(products[0].craftmanship_insight);

      setDesign(products[0].design);
      setDelivery(products[0].delivery);
      setFabrication(products[0].fabrication);
      setCraftsmanship(products[0].craftmanship);
      setSustainableChoice(products[0].a_sustainable_choice);

     
        setCertificateUrls({ authenticityCertificate, carbonDatingCertificate });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []); 



  const items = [
    {
      text: "DESCRIPTION",
      content: (
        <>
          <div className="mb-4 text-[6px] md:text-xs">
            <h2 className="font-bold translate-y-4">DESIGN</h2>
            <div className="flex flex-row gap-2 w-full mt-4">
              <div className="w-20% md:w-[10%]  h-36 md:h-20 gap-2 bg-white ">
                <img 
                 className="h-20 justify-center items-center"
                src= {designPic} alt= 'designImg' />
              </div>
              <div className="w-[90%] h-36 md:h-20 bg-white pl-4 pr-4">
              <p dangerouslySetInnerHTML={{ __html: design }}></p>
              </div>
            </div>

            <h2 className="font-bold mt-4">CRAFTSMANSHIP</h2>
            <div className="flex flex-row gap-2 w-full mt-4">
              <div className="w-20% md:w-[10%]  h-36 md:h-20 gap-2 bg-white ">
              <img 
              className="h-20 justify-center items-center"
                src= {craftPic} alt= 'craftImg' />
              </div>
              <div className="w-[90%] h-36 md:h-20 bg-white pl-4 pr-4">
              <p dangerouslySetInnerHTML={{ __html: craftsmanship }}></p>
              </div>
            </div>

            <h2 className="font-bold mt-4">FABRICATION</h2>
            <div className="flex flex-row gap-2 w-full mt-4">
              <div className="w-20% md:w-[10%]  h-36 md:h-20 gap-2 bg-white ">
              <img 
               className="h-20 justify-center items-center"
                src= {fabricationPic} alt= 'fabricationImg' />
              </div>
              <div className="w-[90%] h-36 md:h-20 bg-white pl-4 pr-4">
              <p dangerouslySetInnerHTML={{ __html: fabrication }}></p>
              </div>
            </div>

            <h2 className="font-bold mt-4">DELIVERY</h2>
            <div className="flex flex-row gap-2 w-full mt-4">
              <div className="w-[20%] md:w-[10%]  h-36 md:h-20 gap-2 bg-white ">
              <img 
               className="h-20 justify-center items-center"
                src= {deliveryPic} alt= 'deliveryImg' />
              </div>
              <div className="w-[90%] h-36 md:h-20 bg-white pl-4 pr-4">
              <p dangerouslySetInnerHTML={{ __html: delivery }}></p>
              </div>
            </div>

            <h2 className="font-bold mt-4">A SUSTAINABLE CHOICE</h2>
            <div className="flex flex-row gap-2 w-full mt-4">
              <div className="w-20% md:w-[10%]  h-36 md:h-20 gap-2 bg-white ">
              <img 
               className="h-20 justify-center items-center"
                src= {sustainPic} alt= 'sustainPic' />
              </div>
              <div className="w-[90%] h-36 md:h-20 bg-white pl-4 pr-4">
              <p dangerouslySetInnerHTML={{ __html: sustainableChoice }}></p>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      text: "TECHNICAL INFORMATION",
      content: (
        <>
          <div className="mb-4">
          <h2 className="font-bold translate-y-4">TECHNICAL INFORMATION</h2>
            <div className="flex items-center bg-white text-black  pl-4 p-2 gap-2 h-10 w-full mt-12">
              <DescriptionIcon />
              <p className="font-bold">
              Technical drawing.pdf
              </p>
            </div>

            <div className="flex items-center bg-white text-black  pl-4 p-2 gap-2 h-10 w-full mt-2">
              <DescriptionIcon />
              <p className="font-bold">
              Technical drawing.pdf
              </p>
            </div>

            <div className="flex items-center bg-white text-black  pl-4 p-2 gap-2 h-10 w-full mt-2">
              <DescriptionIcon />
              <p className="font-bold">
                Technical drawing.pdf
              </p>
            </div>

            
          </div>
        </>
      ),
    },
    {
      text: "HERITAGE AND CRAFTSMANSHIP",
      content: (
        <div>
          <div>
            <div>
              <h2 className="font-bold mt-4">HERITAGE INFORMATION</h2>
              <p className="text-sm mt-4" dangerouslySetInnerHTML={{ __html:productInformation}}></p>
                
             
              <h2 className="font-bold mt-4">Craftsmanship Insight</h2>
              <p className="text-sm mt-4" dangerouslySetInnerHTML={{ __html: craftsmanshipInsight}}></p>
            </div>
          </div>
        </div>
      ),
    },
    {
      text: "CERTIFICATE OF AUTHENTICITY",
      content: (
        <>
          <div className="mb-4">
            <h2 className="font-bold translate-y-4">CERTIFICATE OF AUTHENTICITY</h2>
            <div className="flex items-center bg-white text-black  pl-4 p-2 gap-2 h-10 w-full mt-12">
              <DescriptionIcon />
              <p className="font-bold">
                carbondatingcertificate.pdf
              </p>
            </div>

            <div className="w-full mt-4">
            <img 
            className="w-full h-full"
            src = {certificateUrls.authenticityCertificate} alt="Authenticity Certificate" />
            </div>

            <div className="flex gap-2 justify-center mt-2">
              <button className="flex w-32 h-10 gap-4 justify-center items-center border rounded-md text-white border-black bg-[#35221440]"
                onClick = {()=>{
                  const printWindow = window.open(certificateUrls.authenticityCertificate)
                  printWindow.onload = function(){
                    printWindow.print()
                  }
                }}>
                
                <PrintIcon />
                <p>Print</p>
              </button>
              <button className="flex w-32 h-10 gap-4 justify-center items-center border rounded-md text-white border-black bg-[#35221440]"
              onClick = {()=> window.open(certificateUrls.authenticityCertificate)}
                 >
                <DownloadIcon />
                <p>Download</p>
              </button>
            </div>
          </div>
        </>
      ),
    },
    {
      text: "CARBON DATING CERTIFICATE",
      content: (
        <>
          <div className="mb-4">
            <h2 className="font-bold translate-y-4">CARBON DATING CERTIFICATE</h2>
            <div className="flex items-center bg-white text-black  pl-4 p-2 gap-2 h-10 w-full mt-12">
              <DescriptionIcon />
              <p className="font-bold">
                carbondatingcertificate.pdf
              </p>
            </div>

            <div className="w-full mt-4">
              <img 
              className="h-full w-full"
              src = {certificateUrls.carbonDatingCertificate} alt="Carbon Dating Certificate"/>
            </div>

            <div className="flex gap-2 justify-center mt-2">
              <button className="flex w-32 h-10 gap-4 justify-center items-center border rounded-md text-white border-black bg-[#35221440]"
                onClick = {()=>{
                  const printWindow = window.open(certificateUrls.carbonDatingCertificate)
                  printWindow.onload = function(){
                    printWindow.print()
                  }
                }}>
                <PrintIcon />
                <p>Print</p>
              </button>
              <button className="flex w-32 h-10 gap-4 justify-center items-center border rounded-md text-white border-black bg-[#35221440]"
                 onClick = {()=> window.open(certificateUrls.carbonDatingCertificate)}
                 >
              
                <DownloadIcon />
                <p>Download</p>
              </button>
            </div>
          </div>
        </>
      ),
    },
    {
      text: "REVIEWS",
      content: (
        <div>
          {reviews.map((review, index) => (
            <div key={index}>
              <h2>{review.title}</h2>
              <p>{review.description}</p> 
            </div>
          ))}
        </div>
      ),
    }
    
  ];

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="pb-[1200px] bg-[#edebe8]">
      <div className="md:flex max-w-screen-lg mx-auto h-[100%] text-xs translate-y-6 pt-32">
        {items.map((item, index) => (
          <div
            key={index}
            className={classNames(
              "cursor-pointer p-5 border-b-2 border-black transition-all",
              {
                "border-red-500": index === activeIndex,
              }
            )}
            onClick={() => handleClick(index)}
          >
            {item.text}
            {index === activeIndex && (
              <div className="p-3 text-black md:absolute top-full left-0 right-0">
                {item.content}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalAccordion;
