import axios from "axios";
import { Axios } from "../api/Api";
import React, { useEffect, useState } from "react";
import CategoriesCard from "../components/CategoriesCard";
import { MdEast, MdWest } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ProductsExtra from "../product/ProductsExtra";

const WorkStation = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredProducts, setFilteredProducts] = useState(products);

  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const productsPerPage = 12;

  const navigate = useNavigate();

  const [radioOptions, setRadioOptions] = useState([]);

  useEffect(() => {
    setFilteredProducts(products);
    axios
      .get(
        "https://whew-api.apps.rokswood.com/v1/product_categories?detailed=true"
      )
      .then((response) => {
        const responseData = response.data.data;

        const categoryNames = responseData.items.map((item) => item.name);
        setRadioOptions(["All Products", ...categoryNames]);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(
        "https://whew-api.apps.rokswood.com/v1/product_categories/Workstation%20Table/products"
      )
      .then((response) => {
        const responseData = response.data.data;
        setProducts(responseData.items);
        setFilteredProducts(responseData.items);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(startIndex + productsPerPage, products.length);

  const totalPages = Math.ceil(products.length / productsPerPage);

  const goToPage = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={`px-3 -translate-y-2 bg-white text-[#352214] hover:bg-[#352214] hover:text-white w-5 h-6  text-xs rounded-full ${
            i === currentPage ? "active" : "disabled"
          }`}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const filterProductsByPriceRange = () => {
    // Convert input values to floating-point numbers
    const min = parseFloat(minPrice);
    const max = parseFloat(maxPrice);

    const filtered = products.filter((product) => {
      const productPrice = parseFloat(product.price);
      return productPrice >= min && productPrice <= max;
    });

    setFilteredProducts(filtered);
  };

  return (
    <div className="py-1 w-full">
      <div className="flex flex-row -mb-8 bg-[#EDEBE8]">
        <section className="w-0 md:min-w-fit -pl-40 md:pl-40 pt-40 items-center bg-white">
          <div className="flex flex-col bg-white pr-10">
            <h1 className="py-2  text-[#352214]">CATEGORIES</h1>
            <span
              className="text-center h-[2px] -translate-y-2"
              style={{ width: "40px" }}
            ></span>
          </div>
          {radioOptions.map((option, index) => (
            <div key={index} className="items-center mb-2 text-xs bg-white">
              <label className="items-center">
                <input
                  type="radio"
                  className="form-radio"
                  name="radio-options"
                  value={option}
                  onClick={() => navigate(`/product/${option}`)}
                />
                <span className="ml-2">{option}</span>
              </label>
            </div>
          ))}
        </section>

        <div className="bg-[#EDEBE8] w-full">
          <div className="text-black max-w-screen-lg mx-auto pl-5 pr-5 ">
            <div className="py-10 pt-32 md:pt-40 pb-24">
              <div className="grid grid-cols-1 md:grid-cols-3 justify-between gap-1 lg:gap-10 md:gap-">
                <div className="w-fit flex flex-col items-start lg:gap-3  md:gap-3 gap-3 ">
                  <h1 className=" text-sm md:text-xl py-2  text-[#352214] font-bold">
                    WORKSTATION TABLE
                  </h1>
                  <span
                    className="justify-start text-start h-[1px] -translate-y-3 bg-[#352214]"
                    style={{ width: "40px" }}
                  ></span>
                </div>

<div className="w-fit h-10 flex border border-black">
                <input
                  type="text"
                  placeholder="Min Price"
                  className="w-20 p-2 text-[12px]"
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                />
                <p className="bg-black"> |</p>
                <input
                  type="text"
                  placeholder="Max Price"
                  className="w-20 p-2 text-[12px]"
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                />
                <button onClick={filterProductsByPriceRange} className="border bg-[#352214] text-white p-2 text-[12px] border-black">Filter</button>
                </div>

                <div className=" md:translate-y-7  translate-y-1 float-right">
                  <div className="space-x-2">
                    {currentPage > 1 && (
                      <button
                        onClick={() => goToPage(currentPage - 1)}
                        className="-translate-y-2 bg-white text-[#352214] hover:bg-[#352214] hover:text-white w-5 h-6  text-xs rounded-full"
                      >
                        <MdWest />
                      </button>
                    )}
                    {renderPageNumbers()}

                    {currentPage < totalPages && (
                      <button
                        onClick={() => goToPage(currentPage + 1)}
                        className="-translate-y-2 bg-white text-[#352214] hover:bg-[#352214] hover:text-white w-5 h-6  text-xs rounded-full"
                      >
                        <MdEast />
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-10">
                {Array.isArray(filteredProducts) &&
                  filteredProducts
                    .slice(startIndex, endIndex)
                    .map((item) => (
                      <CategoriesCard key={item._id} product={item} />
                    ))}
              </div>

              <div className="flex justify-between float-right items-center mt-4">
                <div className="flex space-x-2">
                  {currentPage > 1 && (
                    <button
                      onClick={() => goToPage(currentPage - 1)}
                      className="-translate-y-2 bg-white text-[#352214] hover:bg-[#352214] hover:text-white w-5 h-6  text-xs rounded-full"
                    >
                      <MdWest />
                    </button>
                  )}
                  {renderPageNumbers()}

                  {currentPage < totalPages && (
                    <button
                      onClick={() => goToPage(currentPage + 1)}
                      className="-translate-y-2 bg-white text-[#352214] hover:bg-[#352214] hover:text-white w-5 h-6  text-xs rounded-full"
                    >
                      <MdEast />
                    </button>
                  )}
                </div>
              </div>
            </div>

            <ProductsExtra />
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default WorkStation;
