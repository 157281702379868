import React from "react";
import RefundFile from "./RefundFile";

const RefundPolicy = () => {
  return (
    <div className="w-full bg-[#edebe8] pl-4 md:pl-0 pr-4 md:pr-0 overflow-x-hidden">
      <div className="max-w-screen-lg mx-auto py-10 bg-[#edebe8]">
        <h2 className="text-sm md:text-xl font-bold mt-24 md:mt-36 text-[#352214]">
          REFUND POLICY
        </h2>
        <div className="w-9 h-1 bg-[#352214]"></div>
      </div>
      <div className="max-w-screen-lg mx-auto  bg-[#edebe8] text-sm gap-5 flex flex-col pb-20">
        <RefundFile />
      </div>
    </div>
  );
};

export default RefundPolicy;
