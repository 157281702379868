import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../context/AuthContext";

const PaymentOrder = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const subtotal = searchParams.get("subtotal");
  const cartData = JSON.parse(searchParams.get("cartData"));
  const [loggedIn, setLoggedIn] = useState(true);

  const [showShippingForm, setShowShippingForm] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentLink, setPaymentLink] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [billingAddressId, , setBillingAddressId] = useState("");
  const [shippingAddressId, setShippingAddressId] = useState("");
  const { token, isLoggedIn } = useAuth();
  console.log('token', token)

  useEffect(() => {
    axios
      .get("https://whew-api.apps.rokswood.com/v1/payment_methods")
      .then((response) => {
        const paymentMethods = response.data.data;
        setPaymentMethods(paymentMethods.items);
        if (paymentMethods.items.length > 0) { 
          setSelectedPaymentMethod(paymentMethods.items[0].name);
        }
      })
      .catch((error) => {
        console.error("Error fetching payment methods:", error);
      });
  }, []);


  const handleShippingFormToggle = () => {
    setShowShippingForm(!showShippingForm);
  };

  const createOrder = () => {
    if (!isLoggedIn) {
      setErrorMessage("User is not logged in");
      return;
    }

         
    const orderData = {
      billing_address_id: billingAddressId,
      shipping_address_id: shippingAddressId,
    };

    axios
      .post("https://whew-api.apps.rokswood.com/v1/orders", orderData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const paymentLink = response.data;
        setPaymentLink(paymentLink);
      })
      .catch((error) => {
        console.error("Error creating an order:", error);
        setErrorMessage("An error occurred while creating the order");
    });
};

  return (
    <div>
      <div className="w-full">
        <h2 className="text-sm flex gap-4 font-bold">
          YOUR ORDER
          <p className="text-green-500 text-[12px]">
            {" "}
            ({cartData.length}) Items
          </p>
        </h2>

        <div className="w-4/4 grid grid-col-3 font-bold p-6 text-sm gap-4 pl-4 md:pl-0 pr-4 md:pr-0">
          <div className="flex justify-between bg-[#f8f8f8] p-6 -mb-10 border-t-1 pt-8 border-black">
            <div>PRODUCT</div>
            <div>TOTAL </div>
          </div>
          {cartData.map((product) => (
            <div
              key={product.id}
              className="flex justify-between border border-l-0 border-r-0 border-t-1 border-black bg-gray-100 p-6 -mb-10"
            >
              <div>{product.title}</div>
              <div>₦{product.price * product.quantity}</div>
            </div>
          ))}

          <div className="flex justify-between bg-[#f8f8f8] border border-l-0 border-r-0 border-t-1  border-black p-6">
            <div>SUBTOTAL</div>
            <div>₦{subtotal}</div>
          </div>

          <div className="flex justify-between -translate-y-4 bg-[#D7D3CF] p-6">
            <div>TOTAL</div>
            <div>₦{subtotal}</div>
          </div>
        </div>
      

        <h2 className="font-bold pt-6 pb-2">PAYMENT</h2>
        <div>
          {paymentLink ? (
            <a href={paymentLink} target="_blank" rel="noreferrer">
              PROCEED WITH PAYMENT
            </a>
          ) : errorMessage ? (
            <p>{errorMessage}</p>
          ) : (
            "No payment link available"
          )}
        </div>

        <div>
          <div>
            <div className="grid grid-cols-1">
              {paymentMethods.map((method, index) => (
                <div
                  key={index}
                  className="items-center mb-2 text-xs bg-white p-8"
                >
                  <div className="flex">
                    <label className="items-center">
                      <input
                        type="radio"
                        className="form-radio mt-2"
                        name="payment-method"
                        value={method.name}
                        checked={selectedPaymentMethod === method.name}
                        onChange={() => setSelectedPaymentMethod(method.name)}
                      />
                    </label>
                    <p className="ml-2 text-base font-bold">{method.name}</p>

                    <img
                      src={method.image_url}
                      alt={method.name}
                      className="w-24 h-10 -mt-2 ml-2"
                    />
                  </div>

                  <div className="mb-4 mt-2">
                    <label className="block text-[#021228] text-xs font-sm">
                      <input type="checkbox" className="mr-2 leading-tight" />
                      By using this form, you agree to the storage and
                      processing of your data by this website.
                    </label>
                  </div>
                  {loggedIn && ( 
                  <button
                    className="bg-[#352214] text-white hover:bg-white hover:text-[#352214] border
                  font-semibold py-2 px-4 rounded focus:outline-none border-black focus:shadow-outline w-full cursor-pointer"
                    type="button"
                    onClick={createOrder}
                  >
                    ORDER
                  </button>
                  )}
                </div>
              ))}
            </div>

                   </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentOrder;
