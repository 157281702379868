/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import { call, email, home } from "../assets";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    location: "",
    message: "",
    agree: false,
  });
  const [formError, setFormError] = useState("");

  const validateForm = (formData) => {
    const { name, email, phoneNumber, location, message, agree } = formData;

    if (!name || !email || !phoneNumber || !location || !message || !agree) {
      return false;
    }

    return true;
  };
  

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm(formData)) {
      setFormError("Please fill out all fields.");
      return;
    }

    const apiFormData = {
      customer_name: formData.name,
      customer_email: formData.email,
      customer_phone: formData.phoneNumber,
      customer_location: formData.location,
      message: formData.message,
    };

    try {
      const response = await fetch(
        "https://whew-api.apps.rokswood.com/v1/contact_forms",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiFormData),
        }
      );

      if (response.status >= 200 && response.status < 300) {
        const responseData = await response.json();
        console.log("Form submitted successfully", responseData.message);

        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          location: "",
          message: "",
          agree: false,
        });
        setFormError("");
      } else {
        
        setFormError("Form submission failed.");
      }
    } catch (error) {
      setFormError("An error occurred: " + error.message);
    }
  };
  return (
    <div className="w-full bg-[#edebe8] pb-20">
      <div className="max-w-screen-lg mx-auto pl-4 md:pl-0 lg:pl-0 pr-4 md:pr-0 lg:pr-0 py-10 bg-[#edebe8]">
        <h2 className="text-sm md:text-xl font-bold mt-24 md:mt-36  text-[#352214]">
          CONTACT US
        </h2>
        <div className="w-9 h-1 bg-[#352214]"></div>
      </div>
      <div className="max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-2 gap-16 md:gap-28 bg-[#edebe8]">
        <div className="w-full pl-4 md:pl-0 pr-4 md:pr-0">
          <h2 className="text-sm font-bold mb-1">CONTACT US</h2>
          <form>
            <div className="mb-4">
              <p
                className="w-2/3 cursor-pointer text-[12px] md:text-sm py-2 
                 text-black"
              >
                If you have any questions or require advice, please feel free to
                get in touch with us. Our team is here and ready to assist you.
              </p>
            </div>
            <div className="mb-2">
              <p className="w-full py-2 text-black text-sm font-semibold">
                Our offices are open:
              </p>
              <p className="w-full text-black text-[12px] md:text-sm  lg:text-sm">
                Monday to Friday from 8:00 a.m. to 5:00 p.m.
              </p>
            </div>

            <div className="mt-5 flex gap-5">
              <img
                src={home}
                alt="homeImg"
                className="w-10 h-10 bg-white rounded-full p-2 mt-5"
              />
              <div>
                <p className="w-full py-2 text-black text-sm font-semibold">
                  Address:
                </p>
                <p className="w-full text-black text-[12px] md:text-sm font-subFont">
                  Cooperate office: Plot 1003 Morija Close, off Adetokunbo Ademola
                  Crescent, Wuse II Abuja, Nigeria <br />
                  <br />
                  Operation office: 22 Eng. Ayo Adeniyi street, Eyinni, Orita
                  Challenge, Ibadan, Oyo State, Nigeria. +234 912 000 0601
                  (whatsapp/call) +234 904 664 8867 (call only)
                </p>
              </div>
            </div>

            <div className="mt-2 flex gap-5">
              <img
                src={call}
                alt="homeImg"
                className="w-10 h-10 bg-white rounded-full p-2 mt-2"
              />
              <div>
                <p className="w-full py-2 text-black text-sm font-semibold">
                  Phone:
                </p>
                <p className="w-full text-black text-[12px] md:text-sm font-subFont">
                  +234 912 0000 601 (whatsapp/call) <br />
                  +234 904 6648 867 (call only)
                </p>
              </div>
            </div>

            <div className="mb-4 flex gap-5">
              <img
                src={email}
                alt="homeImg"
                className="w-10 h-10 bg-white rounded-full p-2 mt-3"
              />
              <div>
                <p className="w-full py-2 text-black  text-sm font-semibold">
                  Email:
                </p>
                <p className="w-full text-black text-sm font-subFont">
                  info@rkwoodenheritage.com
                </p>
              </div>
            </div>
          </form>
        </div>

        <div className="w-full pl-4 md:pl-0 pr-4 md:pr-0">
          <h2 className="text-sm font-bold mb-2">CONTACT FORM</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4 flex justify-between">
              <input
                className="shadow appearance-none border cursor-pointer rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="name"
                type="text"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />

              <input
                className="shadow appearance-none border cursor-pointer rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="email"
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-4 flex justify-between">
              <input
                className="shadow appearance-none border cursor-pointer  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="phoneNumber"
                type="text"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                required
              />

              <input
                className="shadow appearance-none border cursor-pointer  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="location"
                type="text"
                placeholder="Location"
                value={formData.location}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <input
                className="shadow appearance-none border h-40 cursor-pointer rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="message"
                type="text"
                placeholder="Message"
                value={formData.message}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-500 text-xs font-bold mb-12">
                <input
                  type="checkbox"
                  name="agree"
                  checked={formData.agree}
                  onChange={handleInputChange}
                  className="mr-2 leading-tight"
                />
                By using this form you, agree to the storage and processing of
                your data by this website
              </label>
            </div>
            {formError && <p>{formError}</p>}
            <button
              className="bg-[#352214] text-white hover:bg-white hover:text-[#352214] 
              font-semibold py-2 px-4 mb-4 rounded focus:outline-none border border-black focus:shadow-outline w-60 cursor-pointer"
              type="submit"
                      >
              SEND
            </button>
          </form>
        </div>
      </div>

      <div className="pt-4 translate-y-20">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.8557944488025!2d7.484905973792079!3d9.076899188239842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0a4e49f357c3%3A0x9f7f34a613e31889!2sAdetokunbo%20Ademola%20Cres%2C%20Wuse%20904101%2C%20Abuja%2C%20Federal%20Capital%20Territory!5e0!3m2!1sen!2sng!4v1696060352716!5m2!1sen!2sng"
          allowfullscreen="true"
          width="100%"
          height={400}
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}

export default Contact;
