import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdEast, MdWest } from "react-icons/md";
import BlogPageCard from "./BlogPageCard";
import BlogDetails from "./BlogPost";


const BlogPage = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBlog, setSelectedBlog] = useState(null); 


  const productsPerPage = 12;

  useEffect(() => {
    axios
      .get("https://whew-api.apps.rokswood.com/v1/posts?detailed=true")
      .then((response) => {
        const responseData = response.data.data;
        setProducts(responseData.items);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(startIndex + productsPerPage, products.length);

  const totalPages = Math.ceil(products.length / productsPerPage);

  const goToPage = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={`px-3 -translate-y-2 bg-white text-[#352214] hover:bg-[#352214] hover:text-white w-5 h-6  text-xs rounded-full ${
            i === currentPage ? "active" : "disabled"
          }`}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const handleBlogClick = (blog) => {
    setSelectedBlog(blog);
  };


  return (
    <div className="-mb-8 pb-40 pt-32 md:pt-40 bg-[#EDEBE8] pl-4 md:pl-0 pr-4 md:pr-0">
      <div className="bg-[#EDEBE8] text-black ">
        <div className="flex flex-col gap-3 max-w-screen-lg mx-auto">
          <h1 className=" text-sm md:text-xl py-2  text-[#352214] font-bold">BLOG</h1>
          <span
            className="h-[1px] -translate-y-4 bg-[#352214]"
            style={{ width: "40px" }}
          ></span>
        </div>
      </div>

      <div className="w-full justify-between space-x-10 gap-5">
      {selectedBlog ? (       
        <BlogDetails blog = {selectedBlog}/>
      ) : (
        
     
        <div className="max-w-screen-lg mx-auto py-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {Array.isArray(products) &&
            products
              .slice(startIndex, endIndex)
              .map((item) => (
              <BlogPageCard 
              key={item._id} 
              product={item}
              onClick={()=> handleBlogClick(item)} 

              />
      ))}
        </div>
        )}

        <div className=" -translate-x-32 justify-between float-right items-center mt-4">
          <div className="flex space-x-2">
            {currentPage > 1 && (
              <button
                onClick={() => goToPage(currentPage - 1)}
                className="-translate-y-2 bg-white text-[#352214] hover:bg-[#352214] hover:text-white w-5 h-6  text-xs rounded-full"
              >
                <MdWest />
              </button>
            )}
            {renderPageNumbers()}

            {currentPage < totalPages && (
              <button
                onClick={() => goToPage(currentPage + 1)}
                className="-translate-y-2 bg-white text-[#352214] hover:bg-[#352214] hover:text-white w-5 h-6  text-xs rounded-full"
              >
                <MdEast />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
