import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from 'universal-cookie'
import {useAuth} from '../context/AuthContext'
import axios from "axios";

// const cookies = new Cookies();

function UserAccount() {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");

  const [loginError, setLoginError] = useState("");
  const [registrationError, setRegistrationError] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("isLoggedIn") === "true");
  const { token, login, logout } = useAuth();
  


  const handleLogin = async () => {
    try {
      const response = await axios.post(
        "https://whew-api.apps.rokswood.com/v1/customers/login",
        {
          email: loginEmail,
          password: loginPassword, 
                   
        }
      );

      
      const newToken = response.data.token;
      localStorage.setItem("token", newToken);
     
      
    
     

      window.location.href = "/cart";
      setLoginSuccess(true);
    
      console.log("Login successful. Token:", token); 
      toast.success("Login successful");
     
     
    } catch (error) {
      setLoginError("Invalid email or password.");

      setLoginSuccess(false);
    }
  };

  const handleLogout = async () => {   
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };
  

  const handleRegistration = async (e) => {
  e.preventDefault()
    try {
      const response = await axios.post(
        "https://whew-api.apps.rokswood.com/v1/customers",
        
        {
          email: registerEmail,
          password: registerPassword,         
        }
        
      );

      setRegistrationSuccess(true);
      toast.success("Registration successful, please Login");
      setRegistrationError("");
      setRegisterEmail("");
      setRegisterPassword("");
     
    } catch (error) {
      setRegistrationError("Registration failed. Please try again.");
      setRegistrationSuccess(false);
      
    }
    
  };

  useEffect(() => {
    console.log("Token from local storage:", token);
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div className="bg-[#edebe8] pb-20 pl-4 md:pl-0 lg:pl-0 pr-4 md:pr-0 lg:pr-0">
      <div className="max-w-screen-lg mx-auto py-10 bg-[#edebe8]">
        <h2 className="text-sm md:text-xl font-bold mt-24 md:mt-36 text-[#352214]">
          USER ACCOUNT
        </h2>
        <div className="w-9 h-1 bg-[#352214]"></div>
      </div>
      <div className="max-w-screen-lg mx-auto flex flex-col md:flex-row md:gap-28 bg-[#edebe8]">
      {isLoggedIn ? (
      <button
        className="bg-[#352214] text-white hover:bg-white hover:text-[#352214] 
        font-semibold py-2 px-4 rounded border border-black focus:outline-none w-60 cursor-pointer"
        type="button"
        onClick={handleLogout}
      >
        LOGOUT
      </button>
    ):(

    <>
        <div className="w-full md:w-2/4 mb-8 md:mb-0">
          <h2 className="text-sm font-bold mb-1">Login</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="mb-4">
              <input
                type="text"
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
                placeholder="Email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
                className="shadow appearance-none border rounded w-full cursor-pointer py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>
            <div className="mb-4">
              <input
                type={showPassword ? "text" : "password"}
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
                placeholder="Password"
                className="shadow appearance-none border cursor-pointer 
                first-letter:rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
              <button
                className="text-[12px]"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Hide Password" : "Show Password"}
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-[#021228] text-xs font-sm mb-4">
                <input type="checkbox" className="mr-2 leading-tight" />
                Remember me
              </label>
            </div>
            {loginError && <p className="text-red-500 text-[12px]">{loginError}</p>}
            <button
              className="bg-[#352214] text-white hover:bg-white hover:text-[#352214] 
              font-semibold py-2 px-4 rounded border border-black focus:outline-none w-60 cursor-pointer"
              type="button"
              onClick={handleLogin}
            >
              LOGIN
            </button>
            <Link to="/resetPassword">
              <div className="mb-6">
                <p className="text-[#191a1b] text-[12px]">Forgot password?</p>
              </div>
            </Link>
          </form>
        </div>





        <div className="w-full md:w-2/4">
          <h2 className="text-sm font-bold mb-1">Register</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="mb-4">
              <input
                type="text"
                value={registerEmail}
                onChange={(e) => setRegisterEmail(e.target.value)}
                placeholder="Email"
                className="shadow appearance-none border cursor-pointer rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>
            <div className="mb-4">
              <input
                type={showPassword ? "text" : "password"}
                value={registerPassword}
                onChange={(e) => setRegisterPassword(e.target.value)}
                placeholder="Password"
                className="shadow appearance-none border cursor-pointer rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
              <button 
              className="text-[12px]"
              onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? "Hide Password" : "Show Password"}
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-[12px] font-sm mb-4">
                <input type="checkbox" className="mr-2 leading-tight" />
                By using this website's form, you agree to the storage and
                processing of your data
              </label>
            </div>
            {registrationError && (
              <p className="text-red-500 text-[12px]">{registrationError}</p>
            )}
            <button
              className="bg-[#352214] text-white hover:bg-white hover:text-[#352214] 
              font-semibold py-2 px-4 rounded focus:outline-none border  border-black focus:shadow-outline w-60 cursor-pointer"
              onClick={handleRegistration}
              >
              REGISTER
            </button>
          </form>
        </div>
    
      </>
      )}
      </div>
     
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

    </div>
  );
}

export default UserAccount;
