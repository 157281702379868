import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLoaderData } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { addToCart } from "../redux/rwoodSlice";
import { ToastContainer, toast } from "react-toastify";
import SimilarProduct from "../product/SimilarProduct";

import {
  cartPic,
  emailPic,
  fbPic,
  igPic,
  linkedinPic,
  twitterPic,
} from "../assets";
import ProductDesc from "../product/ProductDesc";
import CardProduct from "../card/CardProduct";

const Product = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const Location = useLocation();
  const [similar, setSimilar] = useState([]);
  const data = useLoaderData();

  useEffect(() => {
    setSimilar(data.data);
  }, [data]);

  useEffect(() => {
    setDetails(Location.state.item);
  }, []);
  return (
    <div className="bg-[#edebe8] pb-32">
      <div className="max-w-screen-lg mx-auto pt-32 md:pt-48 md:flex gap-10 pl-8 md:pl-0 pr-8 md:pr-0">
        <div className="w-fit md:w-2/5">
          <img
            className="w-31/3 h-2/3 mx-auto object-cover"
            src={details.preview_image}
            alt="productImg"
          />
        </div>

        <div className="w-fit md:w-2/5 flex flex-col pt-48 justify-center -translate-y-36 md:-translate-y-52">
          <div>
            <h2 className="text-xl md:text-2xl font-bold gap-6">
              {details.name}
            </h2>
            <span
              className="text-center h-[2px] bg-[#352214] -translate-y-2"
              style={{ width: "40px" }}
            ></span>
            <div>
              <p className="text-sm font-medium text-[#352214]">
                ₦{details.price} <span className="text-xs">VAT Included</span>
              </p>
            </div>
            <div>
              <p className="text-xs font-medium text-[#352214]">
                {details.description}
              </p>
            </div>

            <div className="flex flex-col w-full">
              <div className="gap-10 flex items-center justify-between border-black border-b mt-5 text-xs">
                <p className="font-bold">Dimension</p>
                <span className="font-normal">{details.name}</span>
              </div>
              <div className="gap-10 flex items-center justify-between border-black border-b mt-5 text-xs">
                <p className="font-bold">Plate thickness</p>
                <span className="flex float right">{details.name}</span>
              </div>
              <div className="gap-10 flex items-center justify-between border-black border-b mt-5 text-xs">
                <p className="font-bold">Materials</p>
                <span className="flex float right">{details.name}</span>
              </div>
              <div className="gap-10 flex items-center justify-between border-black border-b mt-5 text-xs">
                <p className="font-bold">Type of edges on the lengths</p>
                <span className="flex float right">{details.name}</span>
              </div>
              <div className="gap-10 flex items-center justify-between border-black border-b mt-5 text-xs">
                <p className="font-bold">Type of edges on widths</p>
                <span className="flex float right">{details.name}</span>
              </div>
              <div className="gap-10 flex items-center justify-between border-black border-b mt-5 text-xs">
                <p className="font-bold">Upper edges</p>
                <span className="flex float right">{details.name}</span>
              </div>
              <div className="gap-10 flex items-center justify-between border-black border-b mt-5 text-xs">
                <p className="font-bold">Coating</p>
                <span className="flex float right">{details.name}</span>
              </div>
              <div className="gap-10 flex items-center justify-between border-black border-b mt-5 text-xs">
                <p className="font-bold">Number of trays</p>
                <span className="flex float right">{details.name}</span>
              </div>
            </div>

            <div className="flex mt-10">
              <span className="p-2">Share</span>
              <div className="flex flex-auto mx-auto w-10 h-5 gap-2 mt-1">
                <a
                  href={`https://www.instagram.com/woodenheritage_/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={igPic} alt="Share on Instagram" />
                </a>

                <a
                  href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    window.location.href
                  )}&text=Check out this awesome content!`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitterPic} alt="Share on Twitter" />
                </a>

                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    window.location.href
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fbPic} alt="Share on Facebook" />
                </a>

                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    window.location.href
                  )}&title=Check%20out%20this%20content`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkedinPic} alt="Share on LinkedIn" />
                </a>

                <a
                  href={`mailto:?subject=Check out this content&body=I thought you might find this interesting: ${encodeURIComponent(
                    window.location.href
                  )}`}
                >
                  <img src={emailPic} alt="Share via Email" />
                </a>
              </div>
              <div className="flex float float-right rounded-sm">
                <button
                  onClick={() =>
                    dispatch(
                      addToCart({
                        _id: details.id,
                        title: details.name,
                        image: details.preview_image,
                        price: details.price,
                        quantity: details.quantity || 1,
                        description: details.description,
                      })
                    ) & toast.success(`${details.name} is added`)
                  }
                  className="bg-[#352214] md:h-10 md:w-32 text-white text-xs rounded-sm p-1 md:p-0"
                >
                  <div className="flex justify-center items-center">
                    <img
                      src={cartPic}
                      alt="cartImg"
                      className="w-3 md:w-5 h-3 md:h-5"
                    />
                    <p className="text-[12px] md:text-xs">add to cart</p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <div className="-translate-y-52">
        <ProductDesc />
      </div>

      <div className="bg-neutral-200 -mt-40">
        <div className="bg-neutral-200 w-full">
          <CardProduct />
        </div>
      </div>

      <div>
        <SimilarProduct similar={similar} />
      </div>
    </div>
  );
};

export default Product;
