import React, { useState, useEffect } from "react";
import { Country, State } from "country-state-city";
import Selector from "./Selector";
import axios from "axios"
import {useAuth} from '../context/AuthContext'

const Billing = () => {
  let countryData = Country.getAllCountries();
  const [country, setCountry] = useState(countryData[0]);
  const [state, setState] = useState();
  const [stateData, setStateData] = useState();
  const [billingData, setBillingData] = useState({}); 
  const [error, setError] = useState(null);
  const { isLoggedIn } = useAuth();
  

  const createBillingAddress = () => {
    const billingDataJson = JSON.stringify(billingData);
    console.log(billingData);
    axios
      .post("https://whew-api.apps.rokswood.com/v1/customer_addresses", billingDataJson, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setBillingData(response.data.data.items);
      })
      .catch((error) => {
        setError("An error occurred while creating the billing address.");
        console.error("Billing address creation error:", error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    createBillingAddress(billingData);
  };


  useEffect(() => {
    setStateData(State.getStatesOfCountry(country?.isoCode));
  }, [country]);

  useEffect(() => {
    stateData && setState(stateData[0]);
  }, [stateData]);


  return (
    <div>
    {isLoggedIn ? (
      <form onSubmit={handleSubmit}>
        <div className="mb-4 flex justify-between">
          <input
            className="shadow appearance-none border cursor-pointer rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="firstname"
            type="name"
            placeholder="First Name"
            required
            onChange={(e) => {setBillingData({...billingData, firstname: e.target.value})
            }}
          />

          <input
            className="shadow appearance-none border cursor-pointer rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="lastname"
            type="name"
            placeholder="Last Name"
            required
            onChange={(e) => {setBillingData({...billingData, lastname: e.target.value})
          }}
          />
        </div>
        <div className="mb-4">
          <input
            className="shadow appearance-none border rounded w-full cursor-pointer py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="company_name"
            type="text"
            placeholder="Company Name (optional)"
            required
            onChange={(e) => {setBillingData({...billingData, company_name: e.target.value})
          }}
          />
        </div>
        <div className="shadow appearance-none mb-4 rounded-sm w-full leading-tight focus:outline-none focus:shadow-outline cursor-pointer">
          <Selector
            data={countryData}
            selected={country}
            setSelected={setCountry}
            required
            onChange={(e) => {setBillingData({...billingData, country: e.target.value})
          }}
          />
        </div>
        <div className="mb-4">
          <input
            className="shadow appearance-none border cursor-pointer 
                rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="street"
            type="text"
            placeholder="Street Number and Name"
            required
            onChange={(e) => {setBillingData({...billingData, street: e.target.value})
            }}
          />
        </div>
        <div className="mb-4">
          <input
            className="shadow appearance-none border cursor-pointer 
                rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="building"
            type="text"
            placeholder="Building, apartment etc (Optional)"
            required
            onChange={(e) => {setBillingData({...billingData, building: e.target.value})
            }}
            
          />
        </div>

        {state && (
          <div className="shadow appearance-none mb-4 rounded-sm w-full leading-tight focus:outline-none focus:shadow-outline cursor-pointer">
            <Selector
              data={stateData}
              selected={state}
              setSelected={setState}
              required
              onChange={(e) => {setBillingData({...billingData, state: e.target.value})
            }}
            
            />
          </div>
        )}

        <div className="mb-4">
          <input
            className="shadow appearance-none border cursor-pointer 
                rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="city"
            type="text"
            placeholder="City"
            required
            onChange={(e) => {setBillingData({...billingData, city: e.target.value})
            }}
            
            
          />
        </div>
        <div className="mb-4">
          <input
            className="shadow appearance-none border cursor-pointer 
                rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="phone"
            type="text"
            placeholder="Phone Number"
            required
            onChange={(e) => {setBillingData({...billingData, phone: e.target.value})
            }}
            
          />
        </div>
        <div className="mb-4">
          <input
            className="shadow appearance-none border cursor-pointer 
                first-letter:rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Email"
            required
            onChange={(e) => {setBillingData({...billingData, email: e.target.value})
            }}
            
            
          />
        </div>

        <div className="mb-4">
          <input
            className="shadow appearance-none border cursor-pointer 
                first-letter:rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="Password"
            required
            onChange={(e) => {setBillingData({...billingData, password: e.target.value})
            }}
            
            
          />
        </div>

        {/* {error && <p>{error}</p>}
        <button type="submit">Submit Billing Address</button> */}

      </form>
      ) : (
        <p>You must be logged in to submit a billing address.</p>
      )}
    </div>
  );
};

export default Billing;
