import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { cart, profile, search, wooden } from "../assets/index";
import { MdOutlineClose, MdOutlineMenu } from "react-icons/md";

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Search from "../components/Search";
import axios from "axios";

const Header = () => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const productData = useSelector((state) => state.rwood.productData);
  const [selectedValue, setSelectedValue] = useState("");
  const [headerCategories, setHeaderCategories] = useState([]);
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const menuRef = useRef(null);

  useEffect(() => {
    axios
      .get(
        "https://whew-api.apps.rokswood.com/v1/product_categories?detailed=true"
      )
      .then((response) => {
        const responseData = response.data.data.items;
        setHeaderCategories(responseData);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setNav(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header>
      <div
        className="w-full mx-auto my-auto sticky top-0 z-50 pb-10 pt-5"
        style={{
          background: "#352214",
          border: "none",
          textDecoration: "none",
          imageRendering: "none",
          height: "40px",
          position: "fixed",
          opacity: "initial",
        }}
      >
        <div className="max-w-screen-lg h-full mx-auto pl-4 md:pl-0 lg:pl-0  pr-4 md:pr-0  lg:pr-0 flex items-center justify-between">
          <div className="text-white" style={{ fontSize: "10px" }}>
            <p>MANUFACTURER OF CLASSIC FURNITURE</p>
          </div>

          <div className="hidden md:flex items-center gap-8">
            <ul className="flex items-center gap-8">
              <Link to="/blogPage">
                <li
                  className="text-base  text-white cursor-pointer duration-300"
                  style={{ fontSize: "10px", decoration: "none" }}
                >
                  BLOG
                </li>
              </Link>

              <Link to="/contactUs">
                <li
                  className="text-base text-white decoration-[1px] cursor-pointer duration-300"
                  style={{ fontSize: "10px" }}
                >
                  CONTACT US
                </li>
              </Link>
            </ul>
          </div>

          <div
            className="hidden md:flex items-center text-white gap-4"
            style={{ fontSize: "10px" }}
          >
            <p>
            +234 904 664 8867</p> | <p> <a href="mailto:info@rkwoodenheritage.com"> info@rkwoodenheritage.com</a></p>
            <a href="https://www.instagram.com/woodenheritage_/">
            <FaInstagram
              className="h-4"
              style={{
                width: "20px",
                height: "20px",
                border: "1px solid",
                borderRadius: "15px",
                padding: "3px",
              }}
            />
            </a>
            <a href="https://twitter.com/woodenheritage_"> 
            <FaTwitter
              className="w-4"
              style={{
                width: "20px",
                height: "20px",
                border: "1px solid",
                borderRadius: "15px",
                padding: "3px",
              }}
            />
            </a>
            <a href="https://www.facebook.com/woodenheritage1">
            <FaFacebookF
              className="w-4"
              style={{
                width: "20px",
                height: "20px",
                border: "1px solid",
                borderRadius: "15px",
                padding: "3px",
              }}
            />
            </a>
            <a href="http://linkedin.com/company/woodenheritage">
            <FaLinkedin
              className="font"
              style={{
                width: "20px",
                height: "20px",
                border: "1px solid",
                borderRadius: "15px",
                padding: "3px",
              }}
            />
            </a>
          </div>

          <div className="flex items-center gap-4 ">
            <select
              className="bg-[#352214] opacity-48 text-white w-18  h-8"
              value={selectedValue}
              onChange={handleChange}
              style={{
                fontSize: "10px",
                borderRadius: "3px",
                padding: "3px",
                border: "1px solid #707070",
              }}
            >
              <option value="1" className="bg-gray-800">
                ₦ NGN
              </option>
              <option value="2" className="bg-gray-800">
                $ USD
              </option>
              <option value="3" className="bg-gray-800">
                £ POUND
              </option>
              <option value="4" className="bg-gray-800">
                ₤ EURO
              </option>
            </select>
          </div>
        </div>
      </div>

      <div
        className="w-full bg-white blur-0 opacity-55 brightness-75 
        sticky top-10 z-50 
       border-b-gray-800"
        style={{ position: "fixed" }}
      >
        <div className="max-w-screen-lg mx-auto flex items-center md:justify-between">
          <div onClick={handleNav} className="block md:hidden pr-2">
            {!nav ? <MdOutlineMenu size={24} /> : <MdOutlineClose size={24} />}
          </div>
          <Link to="/">
            <img className="w-28" src={wooden} alt="woodenLogo" />
          </Link>

          <div className="flex items-center gap-8">
            <div className="flex justify-between items-center mx-auto px-4">
              <ul className="hidden md:flex gap-4">
                <Link to="aboutUs">
                  <li
                    className="text-base font-bold  decoration-[1px] cursor-pointer duration-300"
                    style={{ color: "#352214", fontSize: "8px" }}
                  >
                    {" "}
                    ABOUT US
                  </li>
                </Link>

                {headerCategories.map((category) => (
                  <Link to={`/product/${category.name}`} key={category.id}>
                    <li
                      className="text-base font-bold  decoration-[1px] cursor-pointer duration-300"
                      style={{ color: "#352214", fontSize: "8px" }}
                    >
                      {category.name.toUpperCase()}
                    </li>
                  </Link>
                ))}
              </ul>

              <div
                ref={menuRef}
                className={
                  nav
                    ? "fixed left-0 top-[50px] w-[100%] border-r ease-in-out duration-500 blur-0 opacity-55 bg-white"
                    : "fixed left-[-100%]"
                }
              >
                <ul className="pt-3 uppercase">
                  <Link to="aboutUs">
                    <li
                      className="p-4 font-bold border-b border-b-black  cursor-pointer duration-300"
                      style={{ color: "#352214", fontSize: "8px" }}
                      onClick={handleNav}
                    >
                      {" "}
                      ABOUT US
                    </li>
                  </Link>

                  {headerCategories.map((category) => (
                    <Link
                      to={`/product/${category.name}`}
                      key={category.id}
                      onClick={handleNav}
                    >
                      <li
                        className="p-4 font-bold border-b border-b-black  cursor-pointer duration-300"
                        style={{ color: "#352214", fontSize: "8px" }}
                      >
                        {category.name.toUpperCase()}
                      </li>
                    </Link>

                    
                  ))}
                  
                  <div className="flex gap-12 text-xs cursor-pointer pb-4 pl-4 mt-4">
              <a href="https://www.instagram.com/woodenheritage_/">
                <FaInstagram
                  className="h-4"
                  style={{ 
                    width: "20px",
                    height: "20px",
                    border: "1px solid",
                    borderRadius: "15px",
                    padding: "3px",
                  }}
                />
              </a>
              <a href="https://twitter.com/woodenheritage_">
                <FaTwitter
                  className="w-4"
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "1px solid",
                    borderRadius: "15px",
                    padding: "3px",
                  }}
                />
              </a>
              <a href="https://www.facebook.com/woodenheritage1">
                <FaFacebookF
                  className="w-4"
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "1px solid",
                    borderRadius: "15px",
                    padding: "3px",
                  }}
                />
              </a>
              <a href="http://linkedin.com/company/woodenheritage">
                <FaLinkedin
                  className="font"
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "1px solid",
                    borderRadius: "15px",
                    padding: "3px",
                  }}
                />
              </a>
            </div>
                  
                  {/* <div className="flex gap-8 p-4">
                    <div>
                      <div>
                        <img
                          className="w-4 cursor-pointer"
                          src={search}
                          alt="search"
                          onClick={toggleSearch}
                        />
                      </div>

                      {isSearchVisible && <Search onClose={toggleSearch} 
                        onClick={handleNav}
                      />}
                    </div>

                    <Link to="./account">
                      <img className="w-4" src={profile} alt="profile" 
                        onClick={handleNav}
                      />
                    </Link>
                    <Link to="/cart"
                    onClick={handleNav}>
                      <div className="relative">
                        <img className="w-4" src={cart} alt="cart" />
                        <span className="absolute w-6 -top-3 left-1.5 text-sm flex items-center justify-center font-semibold">
                          {productData.length}
                        </span>
                      </div>
                    </Link>
                  </div> */}
                </ul>
              </div>
            </div>

            <div className="flex gap-2 pr-4 pl-14 md:pl-0">
              <div>
                <div>
                  <img
                    className="w-4 cursor-pointer"
                    src={search}
                    alt="search"
                    onClick={toggleSearch}
                  />
                </div>

                {isSearchVisible && <Search onClose={toggleSearch} />}
              </div>

              <Link to="./account">
                <img className="w-4" src={profile} alt="profile" />
              </Link>
              <Link to="/cart">
                <div className="relative">
                  <img className="w-4" src={cart} alt="cart" />
                  <span className="absolute w-6 -top-3 left-1.5 text-sm flex items-center justify-center font-semibold">
                    {productData.length}
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
