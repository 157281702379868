import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productData: [],
  userInfo: null,
 
};

export const rwoodSlice = createSlice({
  name: "rwood",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = state.productData.find(
        (item) => item._id === action.payload._id
      );

      if (item) {
        item.quantity += action.payload.quantity;
        return;
      } else {
        state.productData=[...state.productData,action.payload]
        return;
      }
    },

    deleteItem: (state, action) => {
      state.productData = state.productData.filter(
        (item) => item._id !== action.payload
      );
    },

    incrementQuantity: (state, action) => {
      const item = state.productData.find(
        (item) => item._id === action.payload._id
      );
      if (item) {
        item.quantity++;
      }
    },

    decrementQuantity: (state, action) => {
      const item = state.productData.find(
        (item) => item._id === action.payload._id
      );
      if (item.quantity > 1) {
        item.quantity--;
      }
    },

    selectCategory: (state, action) => {
      state.selectedCategory = action.payload;
      state.currentPage = 1;
    },    
  },
 
});

export const { 
  addToCart, 
  deleteItem, 
  incrementQuantity, 
  decrementQuantity,
 
} = rwoodSlice.actions;

export default rwoodSlice.reducer;



