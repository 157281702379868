import React from "react";
import { midend2 } from "../assets";

const BeforeFooterAbout = () => {
  const data = [midend2];
  const slides = [
    {
      image: data[0],
      text: "IMPECCABLE CRAFTSMANSHIP, INFINITE POSSIBILITIES",     
    }
  ];

  return (
    <div className="w-full h-auto overflow-x-hidden bg-white">
      <div className="w-screen h-50vh relative pt-40">
        <div
          className="w-fit h-full flex transition-transform duration-1000 flex-1 
          relative ease-out"
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="relative w-screen h-full flex items-center -mt-[150px] md:-mt-[125px] justify-center"
            
            >
              <img
                className="w-screen h-full object-cover"
                src={slide.image}
                alt={`img-${index}`}
              />

              <div className="absolute text-center z-10 top-1/2 transform translate-x-6/7 -translate-y-1/2">
                <p className="text-white font-bold text-[12px] md:text-2xl">{slide.text}</p>               
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BeforeFooterAbout;
