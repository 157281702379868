import React from "react";
import { useLocation } from "react-router-dom";
import { emailPic, fbPic, igPic, linkedinPic, twitterPic } from "../assets";

const BlogPost = ({ blog }) => {
  const location = useLocation();
  const { item } = location.state;
  return (
    <div className="bg-[#edebe8] pb-2">
      <div className="max-w-screen-lg mx-auto pt-48 md:flex gap-10 pl-8 md:pl-0 pr-8 md:pr-0">
        <div className="w-fit md:w-2/5">
          <img
            className="w-31/3 h-2/3 mx-auto object-cover"
            src={item.image}
            alt="PostImg"
          />
        </div>

        <div className="w-fit md:w-2/5 flex flex-col pt-48 justify-center -translate-y-36 md:-translate-y-52">
          <div>
            <h2 className="text-2xl font-bold gap-6">{item.title}</h2>
            <span
              className="text-center h-[2px] bg-[#352214] -translate-y-2"
              style={{ width: "40px" }}
            ></span>
            <div>
              <p className="text-xs font-medium text-[#352214]">{item.body}</p>
            </div>

            <div className="flex mt-10">
              <span className="pr-2">Share</span>
              <div className="flex flex-auto mx-auto w-10 h-5 gap-2 pl-2 -mt-1">
                <a
                  href={`https://www.instagram.com/woodenheritage_/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={igPic} alt="Share on Instagram" />
                </a>

                <a
                  href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    window.location.href
                  )}&text=Check out this awesome content!`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitterPic} alt="Share on Twitter" />
                </a>

                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    window.location.href
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fbPic} alt="Share on Facebook" />
                </a>

                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    window.location.href
                  )}&title=Check%20out%20this%20content`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkedinPic} alt="Share on LinkedIn" />
                </a>

                <a
                  href={`mailto:?subject=Check out this content&body=I thought you might find this interesting: ${encodeURIComponent(
                    window.location.href
                  )}`}
                >
                  <img src={emailPic} alt="Share via Email" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
