import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { wooden } from '../assets';

const LiveChat = () => {
  return (
    <div className="Wooden Heritage">
   
      <FloatingWhatsApp 
        phoneNumber="+2349120000601"
        profilePic = {wooden}
        avatar = {wooden}
        accountName='Wooden Heritage'
        statusMessage='Typically responds within 5 minutes'
        
        buttonStyle={{height: '40px', width: '40px', marginBottom: '40px', backgroundColor: '#352214'}}
        chatboxStyle={{backgroundColor: '#352214'}}
        
        size='20px' />
    </div>
  );
}

export default LiveChat;
