import React from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

const Search = ({ onClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchTerm) {
    setLoading(true);
    axios
      .get(`https://whew-api.apps.rokswood.com/v1/products?detailed=true&search=${searchTerm}`)
      .then((response) => {
        const results = response.data.data.items.slice(0, 5);
        setSearchResults(results);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error searching for products:", error);
        setLoading(false);
      });
  } else {
    setSearchResults([]);
  }
}, [searchTerm]);

const handleResultClick = (product) => {
    const productUrl = `/product/${product.id}`;
  window.location.href = productUrl;
};

  return (
    <div className="fixed -top-0 md:top-1 left-0 w-full">
      <div className=" z-50 md:flex items-center md:h-[600px] justify-center bg-[#00000082]">
        <div className="p-10">
          <div className="relative w-full">
            <div className="mt-32 md:mt-20 w-full">
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="border w-[280px] md:w-[900px] rounded-sm h-12 pl-12 md:pl-32 bg-white"
              />

              <FaSearch 
                className=" md:ml-4 -mt-8 w-8 h-8 -translate-y-1 md:translate-y-0 pl-4 md:pl-0 transform text-blue-400 cursor-pointer" 
                // onClick={handleSearch}
                />
                
            </div>

            <FaTimes
              className="w-10 h-10 translate-x-[250px] md:translate-x-[1000px] -translate-y-[150px] md:-translate-y-[290px] p-2 text-white bg-[#F7941D] text-xl rounded-full cursor-pointer"
              onClick={onClose}
            />
          </div>

          {loading ? (
            <p style={{ background: "white", color: "black", cursor: "pointer" }} 
                >Loading...</p>
          ) : searchTerm && searchResults.length > 0 ? (
            <div>
              <h2 style={{ background: "white", color: "black", cursor: "pointer" }} 
                >Search Results:</h2>
              <ul>
                {searchResults.map((product) => (
                  <li 
                  key={product.id} 
                  onClick={() => handleResultClick(product)}
                  style={{ background: "white", color: "black", cursor: "pointer" }} 
                >
                  {product.name}
                </li>
                ))}
              </ul>
            </div>
          ) : null}

        </div>
      </div>
    </div>
  );
};

export default Search;
